import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';

import AudioPlayer from '../../components/AudioPlayer';

import './styles.scss';
import {VARIATION} from '../../constants';
class Intro extends React.Component {
	constructor(props) {
		super(props);
		const variation = VARIATION;
		const language = strings.getLanguage();
		const audio = require(`../../assets/sounds/${variation}/${language}/2 1 ordkjedetekst.mp3`);
		this.audio = audio;
	}
	render() {
		const {onNextStep} = this.props;

		return (
			<div className="lit-intro">
				<p>
					<AudioPlayer autoplay inline src={this.audio} />
					{strings.ordkjedetestIntro}
				</p>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.showExamples}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
