import React from 'react';

export const text = (
	<span>
		<h2 className="text-center">
			{`Historien om Ignaz Semmelweis – “mødrenes frelser”`}
		</h2>
		<p>
			<strong>
				{`Den østerriksk-ungarske legen Ignaz Semmelweis (1818-1865) er
				kjent for å ha påvist sammenhengen mellom legers mangel på
				håndhygiene og forekomst av barselfeber. Gjennom enkel håndvask
				fikk han dødeligheten blant nybakte mødre til å synke dramatisk.
				Han ble derfor kjent som «mødrenes frelser».`}
			</strong>
		</p>
		<p>
			{`Barseltiden omfatter de første 10-14 dagene etter fødselen. I denne
			perioden skjer det store endringer i kroppen etter det avsluttede
			svangerskapet og fødselen. Noen ganger kan nybakte mødre oppleve å
			få feber. I noen tilfeller blir feberen vedvarende over flere døgn,
			og da brukes betegnelsen barselfeber. I dag vet man at barselfeber
			dreier seg om en bakteriell infeksjon i livmoren som oppstår under
			eller etter fødselen, og som kan behandles med antibiotika. Slik har
			det ikke alltid vært. Barselfeber har forekommet til alle tider. Det
			var lenge en utbredt oppfatning at barselfeber skyldtes
			utenomjordiske påvirkninger, som for eksempel kosmiske forandringer
			i lufta. Dette var forhold man ikke kunne gjøre noe med, og man
			antok derfor at sykdommen var uunngåelig.`}
		</p>
		<p>
			{`I første halvdel av 1800-tallet ble det bygget sykehus i de fleste
			store byene i Europa. Selv om det fortsatt var mest vanlig å føde
			hjemme, ble det stadig mer vanlig å føde på sykehus. Det var gjerne
			kvinner med kompliserte svangerskap og ugifte kvinner som fødte på
			sykehus. Samtidig med økningen i antall fødsler på fødeklinikk,
			oppsto en dramatisk økning i forekomsten av barselfeber.
			Dødeligheten blant kvinner som fødte på sykehus var opptil 25-30
			prosent høy noen steder.`}
		</p>
		<p>
			{`Ignaz Semmelweis tok medisinsk embetseksamen ved Universitetet i
			Wien i 1844 og spesialiserte seg innen fødselshjelp. I 1846 begynte
			han å jobbe som lege ved fødselsklinikken i Wien. Denne klinikken
			ble brukt i utdanningen av leger og jordmødre, og var delt i to
			avdelinger. 1.-avdelingen, hvor legene jobbet, ble brukt til å
			undervise medisinerstudenter. 2.-avdelingen, hvor jordmødrene
			jobbet, ble brukt til opplæring av jordmorstudentene.
			Overklassekvinnene ble som regel lagt inn på 1.-avdeling og fulgt av
			leger under fødselen, mens de fattige kvinnene ble lagt inn på
			2.-avdeling og ble forløst av jordmødre.`}
		</p>
		<p>
			{`Semmelweis ble forferdet da han skjønte at dødeligheten var langt
			høyere på den ene avdelingen enn på den andre. På 1.-avdelingen døde
			18 prosent av kvinnene i barselfeber, mot bare to prosent på
			2.-avdelingen. Han samlet inn opplysninger og laget et diagram som
			viste antall dødsfall som følge av barselfeber på de to avdelingene
			gjennom de siste årene. Semmelweis undret seg over at dersom
			utenomjordiske forandringer var årsaken til barselfeber – hvordan
			kunne disse hjemsøke bare den ene avdelingen, og ikke den andre?`}
		</p>
		<p>
			{`	Semmelweis gikk systematisk til verks for å finne ut hvorfor så
			mange kvinner døde på 1.-avdelingen etter en fødsel uten
			komplikasjoner. Han sammenliknet alle mulige forskjeller mellom de
			to avdelingene. Han slo fra seg at tettheten blant pasientene var en
			mulig forklaring, ettersom det var flere kvinner på rommene på
			2.-avdelingen. Det ble også raskt klart at de mannlige
			legestudentene ikke undersøkte de fødende kvinnene på en mer
			hardhendt måte enn de kvinnelige jordmødrene. Kunne det da være
			psykologiske forklaringer på forskjellene mellom de to avdelingene?
			Presten måtte nemlig gå gjennom fem fødestuer på 1.-avdelingen for å
			komme frem til sykerommet og gi en døende kvinne den siste olje. På
			2.-avdelingen, derimot, gikk han rett til sykerommet uten å måtte gå
			gjennom fødestuene. Semmelweis ønsket å finne ut om synet av presten
			og lyden av bjella som ringte før han gikk inn i en fødestue, gjorde
			kvinnene engstelige og dermed mer mottakelige for barselfeber. Han
			overtalte presten til å gå en omvei og fikk slutt på ringelyden,
			slik at presten gikk ubemerket til sykerommet på 1.-avdelingen. Men
			dødeligheten på 1.avdeling gikk ikke ned. Kunne da forskjellene
			mellom avdelingene skyldes fødestilling? Semmelweis ble tipset om at
			kvinnene på 2.- avdelingen fødte i sideleie, mens de på
			1.-avdelingen fødte mens de lå på rygg. Han fikk innført en regel om
			at også kvinnene på 1.avdelingen skulle føde i sideleie. Men heller
			ikke dette førte til at dødeligheten gikk ned.`}
		</p>
		<p>
			{`Det store gjennombruddet kom da Semmelweis’ kollega og nære venn,
			Jakob Kolletscha, skar seg i fingeren under en obduksjon av liket av
			en kvinne som hadde dødd av barselfeber. Kolletscha fikk en
			infeksjon og utviklet samme symptomer som kvinnene med barselfeber.
			Han døde kort tid etter. Dette satte Semmelweis på sporet av
			sammenhengen mellom obduksjoner og barselfeber. På 1.- avdelingen
			startet legene og medisinerstudentene arbeidsdagene med å foreta
			obduksjoner, før de gikk inn og jobbet med de fødende kvinnene.
			Jordmødrene på 2.-avdelingen deltok ikke i obduksjon av lik, og fikk
			dermed ikke den karakteristiske lukten av “likstoff” på hendene
			sine. Semmelweis beordret derfor studentene og kollegaene til å
			vaske hendene sine og instrumentene med klorkalk etter å ha jobbet
			med de døde kvinnekroppene. Fjerning av likstofflukten medførte at
			dødstallene gikk dramatisk ned i løpet av kort tid. Antall kvinner
			som fikk barselfeber ble snart lavere på 1.- avdelingen enn på
			2.-avdelingen. Semmelweis konkluderte at likstoffene festet seg på
			hendene under obduksjonene og ble ført inn i kvinnenes underliv når
			de ble undersøkt under fødselen.`}
		</p>
		<p>
			{`Selv om tiltaket hadde stor effekt, var barselfeberen fortsatt ikke
			helt utryddet. Semmelweis observerte at kvinner kunne få barselfeber
			selv om legene ikke hadde obdusert tidligere på dagen, og selv om de
			vasket hendene med klorkalk etter en obduksjon. En dag startet
			Semmelweis og hans kollegaer arbeidsdagen med å undersøke en kvinne
			som var syk av barselfeber. De hadde vasket hendene grundig før
			denne undersøkelsen. Etter undersøkelsen av den syke kvinnen,
			undersøkte de tolv helt friske kvinner, uten å vaske hendene først.
			Elleve av de tolv kvinnene fikk barselfeber kort tid etter og døde.
			Dette satte Semmelweis på tanken om at sykdommen ikke bare skyldtes
			likstoffer, men at den også kunne overføres gjennom “råtne stoffer”
			fra levende personer. Han antok at de råtne stoffene kom fra den
			syke kvinnen og hadde klistret seg på hendene til studentene og
			legene. Hvordan smitte overføres mellom mennesker, var ikke klarlagt
			på det tidspunkt da Semmelweis trakk sine slutninger. Men hans funn
			ble grunnlaget for den moderne bakteriologien mange år senere.`}
		</p>
		<p>
			{`Selv om Semmelweis reduserte dødeligheten ved å innføre håndvask,
			ble hans teorier møtt med stor skepsis. Han ble sett på som en
			kverulant. Etter en bitter strid med lederne på sykehuset, ble han
			oppsagt. Mange av hans kollegaer nektet å erkjenne at barselfeber
			kunne overføres gjennom direkte kontakt mellom mennesker. De godtok
			ikke at de selv kunne være årsaken til mange dødsfall. De hadde et
			hellig yrke og kunne umulig ha skitne hender, som tok liv.
			Semmelweis ble hånet og latterliggjort for sine påstander. Han ble
			til slutt tvangsinnlagt på sinnssykehus, hvor han senere døde.`}
		</p>
	</span>
);
export default text;
