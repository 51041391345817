import {combineReducers} from 'redux';

import auth from './auth';
import testStepAnswers from './testStepAnswers';
import currentStepIndex from './currentStepIndex';
import candidateGrade from './candidateGrade';

const rootReducer = combineReducers({
	auth,
	testStepAnswers,
	currentStepIndex,
	candidateGrade,
});

export default rootReducer;
