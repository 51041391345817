import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {updateObject} from '../../utils/reducerUtils';
import FinishSound from '../../components/FinishSound';

import classNames from 'classnames';
import Countdown from '../../components/Countdown';

import strings from '../../utils/strings';
import {VARIATION} from '../../constants';

const whiteSpaceRegex = /\s+/g;

const stripWhiteSpace = (str) => str.replace(whiteSpaceRegex, '');
const removeDuplicateWhiteSpace = (str) => str.replace(whiteSpaceRegex, ' ');
const getNumberOfWhiteSpaces = (str) => {
	const matches = str.match(whiteSpaceRegex);

	if (!matches) {
		return 0;
	}

	return matches.length;
};

const WHITE_SPACE_LIMIT = 3;

class Test extends React.Component {
	constructor() {
		super();

		const results = {};
		const variation = VARIATION;
		const language = strings.getLanguage();
		this.assignments = require(`../../assets/testdata/${variation}/${language}/wordchain-data.json`);
		this.taskIsOver =
			language === 'de'
				? require(`../../assets/sounds/${variation}/${language}/SCR053.mp3`)
				: null;
		for (const assignment of this.assignments) {
			results[assignment.id] = assignment.text;
		}
		this.state = {
			disabledAssignments: {},
			results,
			timeRanOut: false,
			renderFinalSound: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.timeRanOut !== this.state.timeRanOut &&
			this.state.timeRanOut
		) {
			if (this.submitButton) {
				this.submitButton.focus();
			}
		}
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	getDisabledAssignments() {
		const {results} = this.state;

		const disabledAssignments = {};
		for (let i = 0; i < this.assignments.length; i++) {
			const assignmentId = this.assignments[i].id;
			const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
				results[assignmentId]
			);
			const completedAssignment =
				numberOfWhiteSpaces === WHITE_SPACE_LIMIT;
			if (completedAssignment) {
				disabledAssignments[assignmentId] = true;
			}
		}
		return disabledAssignments;
	}

	render() {
		const {onNextStep} = this.props;

		const {disabledAssignments, results, timeRanOut} = this.state;

		let allAssignmentsCompleted = true;
		for (const result in results) {
			if (results.hasOwnProperty(result)) {
				const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
					results[result]
				);
				if (numberOfWhiteSpaces !== WHITE_SPACE_LIMIT) {
					allAssignmentsCompleted = false;
					break;
				}
			}
		}

		return (
			<div className="lit-test lit-word-chain">
				<div className="lit-infobar">
					<Countdown
						duration={240}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
								renderFinalSound: true,
							});
						}}
					/>
				</div>
				{!this.state.renderFinalSound && (
					<p>{strings.ordkjedetestInstructions}</p>
				)}

				<div className="lit-wrapper--thin">
					<div className="lit-word-chains">
						{this.assignments.map((assignment, i) => {
							const {id, text} = assignment;

							const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
								results[id]
							);

							if (timeRanOut && numberOfWhiteSpaces === 0) {
								return null;
							}

							const isDisabled = disabledAssignments[id];

							const onChange = (ev) => {
								if (timeRanOut) {
									return;
								}

								let value = ev.target.value;
								value = value.trim();
								value = removeDuplicateWhiteSpace(value);

								const hasUserEnteredOtherCharacters =
									stripWhiteSpace(value) !== text;
								const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
									value
								);
								if (
									hasUserEnteredOtherCharacters ||
									numberOfWhiteSpaces > WHITE_SPACE_LIMIT
								) {
									return;
								}

								this.setResults(id, value);
							};

							return (
								<div
									className={classNames({
										'lit-input inline': true,
										completed: isDisabled || timeRanOut,
									})}
									key={id}
								>
									<label className="lit-input__label tiny lit-input__label--count">
										{id}
									</label>
									<input
										autoFocus={i === 0}
										className={classNames({
											'lit-input__field': true,
										})}
										disabled={isDisabled || timeRanOut}
										onChange={onChange}
										onFocus={() => {
											const disabledAssignments = this.getDisabledAssignments();
											disabledAssignments[id] = false;

											this.setState({
												disabledAssignments,
											});
										}}
										spellCheck={false}
										value={results[id]}
									/>
									{/* <button
										onClick={() => {
										this.setResults(id, text);
									}}
									>{`Reset`}</button> */}
								</div>
							);
						})}
					</div>
					{(allAssignmentsCompleted ||
						this.state.renderFinalSound) && (
						<div>
							<span>
								<FinishSound
									renderFinalSound={
										this.state.renderFinalSound
									}
									text={strings.Wordchain_is_Finished}
									audioSrc={this.taskIsOver}
								/>

								<button
									className="lit-btn lit bg-primary"
									onClick={() => onNextStep(results)}
								>
									{strings.done}
								</button>
							</span>
						</div>
					)}
				</div>
			</div>
		);
	}
}

Test.propTypes = {
	onNextStep: PropTypes.func.isRequired,
};

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
