import audio2 from '../../../../assets/sounds/youth/se/2 3 Ordkjedetest-Feil-los-tupp-meg-sa.mp3';
import audio3 from '../../../../assets/sounds/youth/se/2 4 Ordkjedetest-Feil-ost-par-vask-jakt.mp3';
import audio4 from '../../../../assets/sounds/youth/se/2 6 Ordkjedetest-Feil-hus-grei.mp3';

const assignments = [
	{
		id: 1,
		text: 'sjunga gunga värd bära',
		answer: 'sjunga gunga värd bära',
	},
	{
		id: 2,
		text: 'kroppsaxråstil',
		answer: 'kropp sax rå stil',
		audio: audio2,
		count: 'andre',
		hasPlayed: false,
	},

	{
		id: 3,
		text: 'katthargenoll',
		answer: 'katt har ge noll',
		audio: audio3,
		count: 'tredje',
		hasPlayed: false,
	},
	{
		id: 4,
		text: 'pennadrycktiout',
		answer: 'penna dryck tio ut',
		audio: audio4,
		count: 'fjerde',
		hasPlayed: false,
	},
];
export default assignments;
