import audio1 from '../../../../assets/sounds/youth/de/diktat/03.mp3';
import audio2 from '../../../../assets/sounds/youth/de/diktat/04.mp3';
import audio3 from '../../../../assets/sounds/youth/de/diktat/05.mp3';
import audio4 from '../../../../assets/sounds/youth/de/diktat/06.mp3';
import audio5 from '../../../../assets/sounds/youth/de/diktat/07.mp3';
import audio6 from '../../../../assets/sounds/youth/de/diktat/08.mp3';
import audio7 from '../../../../assets/sounds/youth/de/diktat/09.mp3';
import audio8 from '../../../../assets/sounds/youth/de/diktat/10.mp3';
import audio9 from '../../../../assets/sounds/youth/de/diktat/11.mp3';
import audio10 from '../../../../assets/sounds/youth/de/diktat/12.mp3';
import audio11 from '../../../../assets/sounds/youth/de/diktat/13.mp3';
import audio12 from '../../../../assets/sounds/youth/de/diktat/14.mp3';
import audio13 from '../../../../assets/sounds/youth/de/diktat/15.mp3';
import audio14 from '../../../../assets/sounds/youth/de/diktat/16.mp3';
import audio15 from '../../../../assets/sounds/youth/de/diktat/17.mp3';
import audio16 from '../../../../assets/sounds/youth/de/diktat/18.mp3';
import audio17 from '../../../../assets/sounds/youth/de/diktat/19.mp3';
import audio18 from '../../../../assets/sounds/youth/de/diktat/20.mp3';
import audio19 from '../../../../assets/sounds/youth/de/diktat/21.mp3';
import audio20 from '../../../../assets/sounds/youth/de/diktat/22.mp3';
import audio21 from '../../../../assets/sounds/youth/de/diktat/23.mp3';
import audio22 from '../../../../assets/sounds/youth/de/diktat/24.mp3';
import audio23 from '../../../../assets/sounds/youth/de/diktat/25.mp3';
import audio24 from '../../../../assets/sounds/youth/de/diktat/26.mp3';
import audio25 from '../../../../assets/sounds/youth/de/diktat/27.mp3';
import audio26 from '../../../../assets/sounds/youth/de/diktat/28.mp3';
import audio27 from '../../../../assets/sounds/youth/de/diktat/29.mp3';
import audio28 from '../../../../assets/sounds/youth/de/diktat/30.mp3';
import audio29 from '../../../../assets/sounds/youth/de/diktat/31.mp3';
import audio30 from '../../../../assets/sounds/youth/de/diktat/32.mp3';
import audio31 from '../../../../assets/sounds/youth/de/diktat/33.mp3';
import audio32 from '../../../../assets/sounds/youth/de/diktat/34.mp3';
import audio33 from '../../../../assets/sounds/youth/de/diktat/35.mp3';
import audio34 from '../../../../assets/sounds/youth/de/diktat/36.mp3';
import audio35 from '../../../../assets/sounds/youth/de/diktat/37.mp3';
import audio36 from '../../../../assets/sounds/youth/de/diktat/38.mp3';
import audio37 from '../../../../assets/sounds/youth/de/diktat/39.mp3';
import audio38 from '../../../../assets/sounds/youth/de/diktat/40.mp3';
import audio39 from '../../../../assets/sounds/youth/de/diktat/41.mp3';
import audio40 from '../../../../assets/sounds/youth/de/diktat/42.mp3';

const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,
	21: audio21,
	22: audio22,
	23: audio23,
	24: audio24,
	25: audio25,
	26: audio26,
	27: audio27,
	28: audio28,
	29: audio29,
	30: audio30,
	31: audio31,
	32: audio32,
	33: audio33,
	34: audio34,
	35: audio35,
	36: audio36,
	37: audio37,
	38: audio38,
	39: audio39,
	40: audio40,
};

export default audioFiles;
