import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {updateObject} from '../../utils/reducerUtils';

import Countdown from '../../components/Countdown';
import strings from '../../utils/strings';

import {VARIATION} from '../../constants';

class Test extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();
		const words = require(`../../assets/testdata/${variation}/${language}/ordforstaelse-data.json`);
		this.words = words;

		const results = {};

		for (const question of this.words) {
			results[question.id] = '';
		}
		this.state = {
			results,
			timeRanOut: false,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep} = this.props;

		const {timeRanOut, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || timeRanOut;

		const minutes = 10;
		const minutesInHour = 60;

		return (
			<div className="lit-test lit-ordforstaelse">
				<div className="lit-infobar">
					<Countdown
						duration={minutes * minutesInHour}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
							});
						}}
					/>
				</div>
				<p>{strings.chooseSynonym}</p>
				<div
					className={classNames({
						'lit-questions': true,
						'time-ran-out': timeRanOut,
					})}
				>
					{this.words.map((question) => {
						const {id, choices} = question;

						const currentQuestionValue = results[id];

						return (
							<div className="lit-question" key={id}>
								<div className="question">
									<span className="lit-input__label lit-input__label--count">
										{id}
									</span>
									<h4 className="question__text">
										{question.word}
									</h4>
								</div>
								<div className="choices">
									{choices.map((choice) => {
										const choiceLetter = choice.letter;
										const idChoice = id + choiceLetter;
										return (
											<span
												className="lit-choice"
												key={idChoice}
											>
												<input
													checked={
														currentQuestionValue ===
														choiceLetter
													}
													className="lit-choice__field"
													id={idChoice}
													onChange={() => {
														if (timeRanOut) {
															return;
														}
														this.setResults(
															id,
															choiceLetter
														);
													}}
													type="radio"
												/>
												<label
													className="lit-choice__label"
													htmlFor={idChoice}
												>
													<div className="checkbox" />
													{choice.choice}
												</label>
											</span>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>

				{isNextButtonVisible && (
					<button
						className="lit-btn bg-primary"
						onClick={() => onNextStep(results)}
					>
						{strings.done}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
