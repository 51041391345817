export const getTypeFromVariation = (variation) => {
	switch (variation) {
		case 'youth':
			return 'youth-screening';
		case 'adult':
			return 'adult-screening';
		default:
			return 'adult-screening';
	}
};
