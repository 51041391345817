import React from 'react';

export const text = (
	<span>
		<h2 className="text-center">{`Vendepunktet`}</h2>
		<p>
			{`«Så kva har du å seie til dette?» spør pappa opprørt. Eg veit ikkje kva eg skal seie. Uansett kva 
			eg svarer nå så blir det bråk. Det krympar seg i magen og i brystet, og angsten breier seg til kjeven 
			og armane. I løpet av få sekund innser eg at eg er i skikkeleg trøbbel, og at dette ikkje vil gå over 
			med det første.`}
		</p>
		<p>
			{`Mamma hadde blitt oppringd av senterleiaren mens eg var på veg heim. Det var forsvunne ting frå 
			butikken eg hadde vore på. Eg er akkurat kommen inn døra heime når eg blir konfrontert med skuldingane.
			 «Eg har ikkje tatt noko», svarer eg. «Det er berre å sjekke alt eg har på meg. Eg har ikkje tatt noko.»`}
		</p>
		<p>
			{`Og eg veit at dei ikkje vil finne noko verken i skolesekken eller i lommene. Mamma tømmer ut alt innhaldet 
			i sekken mens pappa saumfer jakkelommene med brå bevegelsar. «Kor har du fått tak i denne?» spør mamma mens 
			ho tar ut ein og ein ting frå sminkebagen min. «Den der kjøpte eg for tre månadar sidan, minst. Ser du ikkje 
			at han snart er oppbrukt?» svarer eg. Og sånn held vi på til alt frå sminke til gymtøy er gjennomgått. 
			Ingenting av det var nytt, og eg kunne gjere greie for alt. Mamma og pappa vekslar blikk, og eg aner ei viss 
			usikkerheit hos dei.`}
		</p>
		<p>
			{`«Er det noko du vil fortelje oss? Nå har du sjansen til å fortelje oss sanninga. Vi vil høyre henne frå deg
			 sjølv, ikkje frå andre, om du har gjort noko dumt» seier pappa. «Du ser jo sjølv at eg ikkje har tatt noko», 
			 svarer eg. «Senterleiaren seier at dei har sterk mistanke om at du har stole. Dei ringer jo ikkje berre på tull 
			 og påstår noko så alvorleg? Om du lyg for oss nå, så er det eit alvorleg tillitsbrot» fortset han. `}
		</p>
		<p>
			{`Nå kjenner eg ikkje berre på angsten men også på fortvilinga over at dei ikkje trur på meg, enda dei ikkje finn 
			noko. At dei trur meir på andre enn på meg. Dette er ei heilt ny oppleving. Eg vil gråte, men er samtidig så redd 
			at gråten ikkje kjem ut. `}
		</p>
		<p>
			{`«Senterleiaren vil gjerne snakke med oss, så vi skal dra dit nå», seier mamma. «Men skal vi ikkje ete først?»
			 spør eg enda eg ikkje er svolten i det heile tatt. Middagslukta frå kjøkkenet gir likevel håp om at alt kan 
			 bli sett på pause. Eg føler meg uvel. Sjølv ikkje tikka masala, yndlingsretten min, freistar akkurat nå. 
			 «Middagen får vente», svarer mamma. «Vi må få denne saka ut av verda så fort som råd».`}
		</p>
		<p>
			{`Det er merkeleg å køyre same vegen tilbake som eg for under ein time sidan hadde køyrt med bussen. Eg hadde vore 
			ein kjapp tur innom kjøpesenteret etter skoletid, og tok ein milkshake saman med nokon frå klassen. Det er stille i 
			bilen. Eg har musikken på lågt så eg kan høyre viss dei snakkar seg imellom. Men dei vekslar ikkje eit ord. Eg prøver 
			å lese ansiktet til mamma i sidespegelen, mens eg kan studere pappa meir direkte frå baksetet. Han ser veldig 
			alvorleg og konsentrert ut der han køyrer. Han er sikkert veldig letta for at dei ikkje fann noko. Begge tenker 
			sikkert på kva dei skal seie på møtet.`}
		</p>
		<p>
			{`Inne på senteret går vi inn ei grøn dør som er skilta med «Leiing». Eg har knapt lagt merke til denne tidlegare, 
			enda eg har gått forbi der fleire gonger på veg til toalettet. Ei securitasvakt spør om vi skal møte senterleiaren,
			 og viser oss veg. Vi kjem inn i ein liten korridor med mange dører, og i enden står ei dør på gløtt. Inne på kontoret 
			sit to damer og ei anna securitasvakt. Ho eine har eg aldri sett på ordentleg, berre i media. Ho har langt lyst hår, 
			knallraude negler og lepper, og er stilig kledd. Ho andre kjenner eg igjen frå butikken. Ho har brunt hår som er samla 
			i ein lang hestehale, og er ganske pen. Securitasvakta kjenner eg også igjen. Han bruker nokre gonger å gå rundt, 
			og nokre gonger bruker han å sitte i service-skranken midt inne i senteret i 1.etasjen. `}
		</p>
		<p>
			{`Vi sit oss rundt eit stort møtebord, og senterleiaren innleiar med å fortelje at det alltid er like trist når unge 
			menneske blir tatt for butikktjuveri. «Vi melder alle fordi det elles vil vere umogeleg å velje kven vi skal melde og 
			kven som skal sleppe unna. Butikkdama tar ordet og seier at «Eit mammahjarte brest når eg må melde nokon som er tatt 
			for første gong. Det er veldig vondt. Men kva skal eg gjere? Eg må sjølv betale for å få varer inn til butikken min, 
			og eg må framleis betale lønn til dei tilsette og betale leige for butikklokala, sjølv om folk stel og eg taper inntekt. 
			Det er så jævlig urettferdig at eg skal lide for at enkelte ikkje skjønner forskjellen på mitt og ditt». `}
		</p>
		<p>
			{`Nå er det securitasvakta som tar ordet: «Etter mange års erfaring kan vi sjå potensielle butikktjuvar. Det handlar om 
			korleis dei oppfører seg i butikken, sjølv om dei trur at dei oppfører seg normalt. Og mens gutar typisk stel aleine, så 
			stel jentene når dei er saman med andre. Eit vanleg eksempel er når tre venninner er inne samtidig og to gjer kva dei kan 
			for å avleie merksemda mens den tredje stel. Da veit vi at det er ei vel gjennomtenkt handling. Senterleiaren overtar ordet, 
			og vekslar mellom å sjå på meg og foreldra mine: «Nokre gonger blir ein person i ein vennegjeng tatt. Men vi veit kven dei 
			andre er. Da vil også dei andre vennene bli møtt med mistanke neste gong dei er i butikkane våre, sjølv om dei er uskyldige. 
			Alle blir stempla dersom dei er i venneflokken til ein butikktjuv.»`}
		</p>
		<p>
			{`Eg aner nå eit lite smil hos mamma. Ho veit at dette ikkje angår meg. Eg opptrer ikkje i nokon gjeng. Sjølv om vi har 
			budd på denne staden i to år nå, har det vore vanskeleg å få nye venner. Dronninga i klassen og hoffet hennar bitch-blikka 
			meg ganske lenge, og gutane er nokre skikkelege drama queens som spreier falske rykte om folk. Heldigvis har det blitt betre 
			i det siste, og eg har liksom blitt meir akseptert. I morgon kveld skal eg til og med få komme heim på filmkveld hos dronninga. 
			Men eg saknar gammalklassen.`}
		</p>
		<p>
			{`«Uansett om den vi ferskar er fylt 15 år eller ikkje, så melder vi saka til politiet», fortset senterleiaren. 
			«Du blir da registrert som meld, og må møte hos politiet. Barnevernet blir ofte også kontakta.» `}
		</p>
		<p>
			{`Det verkar som dei har snakka om dette mange gonger før. Senterleiaren, butikksjefen og securitasvakta snakkar ikkje i munnen 
			på kvarandre, men dei overtar for kvarandre som i eit stafettløp. Eg høyrer på måten mamma og pappa pustar på at dei har tenkt 
			å seie noko nå, dei også. Men før nokon av dei kjem så langt, fortset securitasvakta mens han ser på meg: Viss du er fylt 15 kan 
			du bli straffa med bøter. Da blir du registrert som straffa person, men i enkelte tilfelle kan du vere heldig og berre få 
			påtaleunnlating. Det vil seie at du er skyldig, men ikkje får straff denne gongen».`}
		</p>
		<p>
			{`Senterleiaren ser på pappa, sjølv om det er meg ho snakkar til: «Hugs at viss du blir tatt for å stele kan også mogelegheitene 
			dine vidare i livet bli avgrensa. Du kan få utsetting på når du kan ta mopedførarbeviset og førarkortet, i tillegg kan du få problem 
			med å få jobbar eller studium som krev sikkerheitsklarering. Så flytter ho blikket til meg og spør: «Er du fylt 15?». `}
		</p>
		<p>
			{`Nå har pappa fått nok. Han vrir seg i stolen og kremtar høgt. Stemmen hans skjelv når han begynner å prate, og det gjer han berre 
			når han er sint. «Og de er kanskje også kjente med at det er straffbart å komme med falske skuldingar og fabrikkere bevis? Er det ikkje 
			tre år som er strafferamma for slikt? Det skal eg nok finne ut av». Det blir plutseleg ein annan temperatur i rommet. Senterleiaren prøver 
			å seie noko, men blir avbroten av pappa. «Vi ransaka skolesekken og jakkelommene hennar med det same ho kom inn døra heime. Frå vi blei 
			varsla og til ho kom heim gjekk det veldig kort tid, og ho hadde ikkje hatt sjanse til å gøyme noko. Og berre så det er sagt så fann vi 
			ingenting som ikkje er hennar. Ingenting! Derfor er det veldig merkeleg å sitte og høyre på alt de lirer av dykk. Utan at de har noko som 
			helst belegg for det de skuldar henne for.»`}
		</p>
		<p>
			{`Eg kjenner på ein enorm lette. Mamma og pappa trur på meg likevel. Og pappa har jo heilt rett. Det er ingen som har tatt meg med stolne 
			ting. `}
		</p>
		<p>
			{`Sjølv om det ennå er seint på ettermiddagen, kjennest det som at det er seint på kvelden. Det har skjedd mykje. Eg rekk å kjenne på at 
			eg er svolten, at eg gleder meg til å komme heim og ete, og at det er godt at det er helg. Men så kjem securitasvakta med orda som får heile 
			verda mi til å falle i grus. `}
		</p>
		<p>
			{`«Vi har bevis. Ikkje berre frå i dag, men også frå to tidlegare tilfelle, og i ulike butikkar. Som sagt så stel jenter helst i flokk. Men 
			det har ikkje skjedd i dette tilfellet. Vi har hatt mistanke over tid, og mistanken vår har blitt fanga opp og bekrefta gjennom opptak frå 
			overvakingskameraa våre.»`}
		</p>
		<p>
			{`Kroppen er paralysert. Ansiktet er heilt noment, som om eg har mista alt blodet. Mamma og pappa ser på meg med vantru blikk. Stemmene i 
			rommet er langt borte. Det er som at eg ikkje skjønner kva som skjer rundt meg. Eg kjenner på skamma både for det eg har gjort og for at eg 
			har loge til dei. Og oppi alt kjenner eg på ein lette. `}
		</p>
	</span>
);
export default text;
