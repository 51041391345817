import getFetchOptions from '../utils/getFetchOptions';

import {ENDPOINT_URL} from '../constants';

export default function submitTestUserInfo(testUser) {
	return fetch(
		`${ENDPOINT_URL}/test/info`,
		getFetchOptions({
			method: 'PATCH',
			body: JSON.stringify(testUser),
		})
	);
}
