import React from 'react';
import {connect} from 'react-redux';

import './styles.scss';

import mojs from 'mo-js';

class Counter extends React.Component {
	componentDidMount() {
		this.initMojs();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.count < this.props.count) {
			this.sparkle();
		}
	}

	initMojs() {
		const circle = this.circle;

		this.mainCircle = new mojs.Shape({
			fill: 'none',
			radius: 53,
			strokeWidth: {20: 0},
			scale: {0.5: 1},
			opacity: {0.5: 1},
			duration: 700,
			left: '50%',
			top: '50%',
			easing: 'cubic.out',
			stroke: 'cyan',
		});

		circle.appendChild(this.mainCircle.el);

		this.smallCircles = [];
		const colors = ['#4895e2', '#3629b5', 'cyan'];
		this.smallCircles.push(
			new mojs.Shape({
				fill: 'none',
				radius: 50,
				strokeWidth: {15: 0},
				scale: {0.7: 1},
				duration: 1000,
				easing: 'cubic.out',
				parent: this.mainCircle.el,
				left: '50%',
				top: '50%',
				stroke: colors[0],
				delay: 100,
			})
		);

		const randoms = 3;

		for (let i = 0; i < randoms; i++) {
			this.smallCircles.push(
				new mojs.Shape({
					fill: 'none',
					scale: {0: 1},
					opacity: {0.5: 1},
					duration: 500,
					easing: 'cubic.out',
					parent: this.mainCircle.el,
					strokeWidth: {30: 0},
					left: '50%',
					top: '50%',
					stroke: colors[i % colors.length],
					delay: 'rand(0, 350)',
					x: 'rand(-50, 50)',
					y: 'rand(-50, 50)',
					radius: 'rand(5, 20)',
				})
			);
		}
	}

	sparkle() {
		this.mainCircle.replay();

		for (let smallCircle of this.smallCircles) {
			smallCircle.generate().replay();
		}
	}

	render() {
		const {count} = this.props;

		return (
			<div
				className="lit-circle"
				id="circle"
				onClick={() => {
					this.sparkle();
				}}
				ref={(elem) => {
					this.circle = elem;
				}}
			>
				<div className="lit-circle__static" />
				<p className="lit-circle__text" id="count">
					{count}
				</p>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Counter);
