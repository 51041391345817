import React from 'react';
import modilSound from '../../../../assets/sounds/youth/de/3 04 Tulleord Modil er feil.mp3';
import falkSound from '../../../../assets/sounds/youth/de/3 05 Tulleord Falk er riktig.mp3';
import vuksSound from '../../../../assets/sounds/youth/de/3 04 Tulleord Modil er feil.mp3';
import flyntSound from '../../../../assets/sounds/youth/de/3 04 Tulleord Modil er feil.mp3';
import gjalteSound from '../../../../assets/sounds/youth/de/3 04 Tulleord Modil er feil.mp3';

import example from '../../../../assets/sounds/youth/de/3 02 Tulleord Sgo.mp3';
import dinTur from '../../../../assets/sounds/youth/de/3 03 Tulleord Na er det din tur.mp3';
import starterTesten from '../../../../assets/sounds/youth/de/3 13 Tulleord Starter testen.mp3';

export const exampleSound = example;
export const yourTurnSound = dinTur;
export const startTheTestSound = starterTesten;

export const hereYouSee1 = `giesel, tiese, lurke, gliefe`;
export const hereYouSee2 = `tihre`;

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'giesel',
		},
		{
			word: 'tiese',
		},
		{
			word: 'lurke',
		},
		{
			word: 'gliefe',
		},
		{
			word: 'tihre',
			isCorrect: true,
		},
	],
};

export const assignments = [
	{
		id: 2,
		choices: [
			{
				word: 'Maft',
				comment:
					'Das ist falsch. Die richtige Antwort ist das dritte Wort: Tseit, also Tiere auf dem Bauernhof zum Beispiel. Es ist nicht richtig geschrieben, aber es klingt wie das richtige Wort, wenn Du es aussprichst. Die anderen Wörter sehen richtigen Wörtern ähnlich. Wenn Du sie aussprichst, merkst Du jedoch, dass es keine richtigen Wörter sind.',
				sound: modilSound,
			},
			{
				word: 'Hend',
				comment:
					'Das ist falsch. Die richtige Antwort ist das dritte Wort: Tseit, also „Wir haben noch etwas Tseit, bis die nächste Schulstunde beginnt“ zum Beispiel. Es ist nicht korrekt geschrieben, aber es klingt wie das richtige Wort, wenn Du es aussprichst. Die anderen Wörter sehen richtigen Wörtern ähnlich. Du sie aussprichst, merkst Du jedoch, dass es keine richtigen Wörter sind.',
				sound: vuksSound,
			},
			{
				word: 'Tseit',
				comment: 'Das ist richtig.',
				sound: falkSound,
			},
			{
				word: 'Krief',
				comment:
					'Das ist falsch. Die richtige Antwort ist das dritte Wort: Tseit, also „Wir haben noch etwas Tseit, bis die nächste Schulstunde beginnt“ zum Beispiel. Es ist nicht korrekt geschrieben, aber es klingt wie das richtige Wort, wenn Du es aussprichst. Die anderen Wörter sehen richtigen Wörtern ähnlich. Du sie aussprichst, merkst Du jedoch, dass es keine richtigen Wörter sind.',
				sound: flyntSound,
			},
			{
				word: 'Pand',
				comment:
					'Das ist nicht korrekt. Die richtige Antwort ist das dritte Wort: Tseit, also „Wir haben noch etwas Tseit, bis die nächste Schulstunde beginnt“ zum Beispiel. Es ist nicht korrekt geschrieben, aber es klingt wie das richtige Wort, wenn Du es aussprichst. Die anderen Wörter sehen richtigen Wörtern ähnlich. Du sie aussprichst, merkst Du jedoch, dass es keine richtigen Wörter sind.',
				sound: gjalteSound,
			},
		],
	},
];

export const hereYouSeeExample = [
	{
		textData: (
			<span>
				Die richtige Antwort ist das fünfte Wort:{' '}
				<span style={{color: 'Gold'}}>
					<b>Tihre</b>
				</span>
				, also Tiere auf dem Bauernhof zum Beispiel. Es ist nicht
				richtig geschrieben, aber es klingt wie das richtige Wort, wenn
				Du es aussprichst. Die anderen Wörter sehen richtigen Wörtern
				ähnlich. Wenn Du sie aussprichst, merkst Du jedoch, dass es
				keine richtigen Wörter sind.
			</span>
		),
	},
];
