import React from 'react';

export const text = (
	<span>
		<h2 className="text-center">{`Unbeschwerte Sommerferien?`}</h2>
		<p>
			{`Der Waldweg staubte, als Theo kräftig in die Pedale seines Fahrrads trat. Er hatte sich mit seiner Mutter gestritten, weshalb er jetzt später dran war als vereinbart. Bestimmt warteten Lisa und Jonas schon am Baumhaus auf ihn. Theo war irgendwann einfach losgefahren und hatte seine Mutter stehen gelassen. Warum schrieb sie ihm auch vor, um 18 Uhr zuhause zu sein? Immerhin waren Sommerferien! Und außerdem war er auch kein kleines Kind mehr. In Gedanken versunken, verpasste Theo fast die Abzweigung zum Trampelpfad. Er stieg ab und schob das letzte Stück. Das Baumhaus, welches Lisa, Jonas und er vor einigen Jahren gemeinsam mit ihren Vätern gebaut hatten, war ihr ganzer Stolz. Es war im Wald versteckt, hoch in den Bäumen und nur mit einer Strickleiter zu erreichen. Theo lehnte sein Fahrrad gegen einen Baum und schlug sich durch das Gebüsch bis zur Lichtung durch. `}
		</p>

		<p>
			{`Wie er vermutet hatte, waren Lisa und Jonas bereits da. Ein fragendes „Theo?“ hörte er von oben, kaum, dass er die Lichtung betreten hatte. „Ja, ich bin es!“, rief er ihnen zu, während er anfing mit geübten Griffen die knarzende Strickleiter hochzuklettern. „Wir müssen unbedingt diese verdammte Strickleiter reparieren“, beschwerte er sich, als er den Kopf durch die Lucke steckte. „Welche Laus ist dir denn über die Leber gelaufen?“ erkundigte sich Lisa. „Meine Mutter“, fing Theo an und war im nächsten Moment nicht mehr zu bremsen. Denn mit wem konnte man sich schon besser über die eigenen Gefühle und Sorgen unterhalten als mit den besten Freunden?`}
		</p>
		<p>
			{`Der Nachmittag ging schnell vorüber, während die Drei eifrig Pläne für den Sommer schmiedeten. Auch den Ärger mit seiner Mutter hatte Theo bald vergessen, denn er freute sich auf die Ferien und die unbeschwerte Zeit. Sie würden sicherlich viele Stunden im Baumhaus verbringen. Sie wollten aber auch an den Badesee fahren, einen Ausflug mit dem Kanu unternehmen, eine Radtour machen und in den Kletterpark gehen.`}{' '}
		</p>
		<p>
			{`„Ich muss mal aufs Klo“, murmelte Theo und schob seine Beine durch die Luke im Boden. Er setzte die Füße auf die ersten Sprossen der Strickleiter und begann herabzuklettern. Plötzlich gab es einen Ruck, die Strickleiter gab unter Theo nach und er fiel. Im nächsten Moment schoss ein stechender Schmerz durch seine Schulter. Er spürte den harten Erdboden unter sich, nahm aber sonst nur den pochenden Schmerz wahr. Ihm stiegen Tränen in die Augen und ihm wurde ganz flau im Magen. „Theo!“, hörte er Lisa und Jonas, „Theo! Hörst Du uns? Geht es Dir gut?“ „Meine Schulter“, brachte er leise hervor. Dann wiederholte er etwas deutlicher: „Ich glaube, ich habe mir die Schulter verletzt.“ „Kannst Du Dich bewegen?“ „Nicht wirklich.“ „Bleib lieber liegen und bewege Dich nicht!“, kam sofort Lisas Anweisung. Er hörte die aufgeregten Stimmen von Jonas und Lisa über sich, aber es fiel ihm schwer, sich auf diese zu konzentrieren. Er brauchte die beiden auch nicht zu verstehen, um zu wissen, worüber sie diskutierten. Sie brauchten Hilfe, das war klar. Alleine kam er hier nicht weg. Doch wie sollten sie das anstellen? Lisa und Jonas saßen im Baumhaus fest und konnten keine Hilfe holen. Ihre Handys hatten hier im Wald fast keinen Empfang. Bei dieser Erkenntnis stieg Panik in ihm auf und es schnürte ihm die Kehle zu. Sollte er versuchen loszulaufen und nach besserem Handynetz zu suchen? Aber das konnte er sich bei seinen Schmerzen kaum vorstellen. Allein bei dem Gedanken sich zu bewegen, wurde ihm übel. Doch blieb ihm eine Wahl? Er spürte Tränen, die ihm die Wangen herunterkullerten. „Reiß dich zusammen, heulen hilft keinem!“, ermahnte er sich selbst im Flüsterton. Irgendetwas musste er tun. Sehr vorsichtig bewegte er seinen anderen Arm und schob ihn über seinen Kopf. Doch bei dem Versuch, sich hochzudrücken, schoss ihm wieder ein stechender Schmerz durch die Schulter und er brach die Bewegung ab.`}
		</p>
		<p>
			{`Er dachte an seine Eltern. Seine Mutter ahnte wohl, wo er hingefahren war. Aber wann würden sie nach ihm suchen? Er war mitten im Streit einfach losgefahren und sie hatten keine Uhrzeit abgesprochen. Wieder schossen ihm Tränen in die Augen. Was hatten Lisa und Jonas mit ihren Eltern vereinbart? Wann würden die beiden vermisst werden? Über sich hörte er nun nur noch Lisa reden. Sie klang erleichtert und er konnte hören, wie sie den Weg zum Baumhaus beschrieb. Hatte sie etwa doch jemanden erreichen können? Nach einem kurzen Moment Stille hörte er Jonas über sich. „He Theo! Alles wird gut! Hilfe ist unterwegs!“ „Wie?“, setzte er an, aber Lisa kam ihm zuvor „Einen Notruf kann man auch bei sehr schwachem Netz absetzen.“ Dankbar seufzte er auf und entspannte sich etwas. Die clevere Lisa – was würden sie nur ohne sie tun? „Es dauert aber ein bisschen, die können ja nicht mit dem Krankenwagen direkt hierherfahren“, fügte Lisa hinzu. Theo ließ den Kopf sinken und versuchte, an etwas Schönes zu denken: An eine leckere Kugel Vanilleeis mit Erdbeersoße und Schokostreuseln, an seine kleine Schwester, die gerade laufen gelernt hatte, an den Sommer, in dem sie das Baumhaus gebaut hatten. Doch immer wieder wanderten seine Gedanken zu seiner schmerzenden Schulter. Die Minuten zogen sich endlos dahin, wie Kaugummi. Zwischendurch fragte er einmal nach, wie viel Zeit schon vergangen war. Aber die Info, dass Lisa vor sieben Minuten telefoniert hatte, half ihm auch nicht wirklich weiter. Dann – endlich – hörte er mehrere Motoren in der Ferne. Lisa und Jonas fingen an zu rufen. Einige Minuten geschah gar nichts, dann hörte er mehrere Stimmen und Schritte. „Sie kommen, Theo! Ich kann sie schon sehen!“, rief Lisa ihm von oben zu. Tatsächlich dauerte es nicht mehr lange, bis sich die Lichtung mit Menschen füllte. Erleichtert atmete er auf. Hilfe war da, jetzt würde alles gut werden.`}
		</p>
		<p>
			{`Die nächsten Minuten verliefen wie in Zeitlupe. Einige Feuerwehrleute halfen Lisa und Jonas mit einer Leiter aus dem Baumhaus zurück auf den Boden. Währenddessen kümmerten sich zwei Notfallsanitäter um Theo, gaben ihm Wasser und Schmerzmittel und luden ihn vorsichtig auf eine Trage. Wie durch einen Schleier nahm er wahr, dass er über den Waldweg zum Rettungswagen getragen wurde. Er war erleichtert, dass ihm nun geholfen wurde.  Dennoch dachte er die ganze Zeit an den bevorstehenden Sommer und die vielen Unternehmungen, die sie geplant hatten. Die Ferien mit einer kaputten Schulter zu verbringen – so hatte er sich das wahrlich nicht vorgestellt.`}
		</p>
	</span>
);
export default text;
