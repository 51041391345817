/**
 * Will parse falsy and truthy strings to bools. It can also map "null" and "undefined" to false if desired
 * @param {String} inputString - The string we want to parse, usually data from user input
 * @param {Boolean} undefinedIsFalse - If true, "undefined" and undefined will result in false. Otherwise, will return undefined. Default: false
 * @param {Boolean} nullIsFalse - If true, "null" and null will result in false. Otherwise, will return null. Default: false
 * @param {Boolean} strictTrue - If true, only "1" and "true" will yield a true answer. By default, any non-falsy string will yield a true return.
 * @returns {Boolean} - Will return true or false depending on the options set. May return null or undefined if nullIsFalse or undefinedIsFalse is not enabled
 */
export const parsebool = (
	inputString,
	undefinedIsFalse = false,
	nullIsFalse = false,
	strictTrue = false
) => {
	if (typeof inputString === 'undefined' || inputString === 'undefined') {
		if (undefinedIsFalse) {
			return false;
		}
		// eslint-disable-next-line no-undefined
		return undefined;
	}

	if (inputString === '0' || inputString === 'false') {
		return false;
	}

	if (inputString === 'null') {
		if (nullIsFalse) {
			return false;
		}
		return null;
	}

	if (inputString === null) {
		if (nullIsFalse) {
			return false;
		}
		return null;
	}

	if (inputString) {
		if (strictTrue) {
			if (inputString === '1' || inputString === 'true') {
				return true;
			}
			return false;
		}
		return true;
	}

	return false;
};
