import getFetchOptions from '../utils/getFetchOptions';
import strings from '../utils/strings';
import {ENDPOINT_URL, TEST_STEP_IDS, VARIATION} from '../constants';
import {getTypeFromVariation} from '../utils/variantUtils';

export default function submitTestAnswers(step, answers, grade) {
	const testNr = TEST_STEP_IDS[step];
	return fetch(
		`${ENDPOINT_URL}/test/result/${testNr}`,
		getFetchOptions({
			method: 'POST',

			body: JSON.stringify({
				answers,
				type: getTypeFromVariation(VARIATION),
				grade,
				language: strings.getLanguage() ? strings.getLanguage() : 'no', //get language key, if unavailable, default to 'no'
			}),
		})
	);
}
