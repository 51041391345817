import {SET_CURRENT_STEP_INDEX} from '../actions/index';

const currentStepIndex = (state = 0, action) => {
	switch (action.type) {
		case SET_CURRENT_STEP_INDEX:
			return action.stepIndex;

		default:
			return state;
	}
};

export default currentStepIndex;
