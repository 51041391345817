import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer, createMigrate} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
import {ENVIRONMENT} from './constants';

const updatedVersion = 26;

const migrations = {
	[updatedVersion]: (state) => {
		return {
			auth: state.auth,
		};
	},
};

const config = {
	key: 'literate-screening-storage',
	storage,
	whitelist: [],
	version: updatedVersion,
	migrate: createMigrate(migrations),
};

const reducer = persistReducer(config, rootReducer);

function configureStore() {
	const middlewares = [thunk];

	if (ENVIRONMENT !== 'production') {
		const createLogger = require('redux-logger').createLogger;
		const logger = createLogger();
		middlewares.push(logger);
	}

	const store = createStore(reducer, applyMiddleware(...middlewares));

	const persistor = persistStore(store);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept(() => {
			const nextReducer = require('./reducers').default;
			store.replaceReducer(nextReducer);
		});
	}

	return {store, persistor};
}

const {store} = configureStore();
export default store;
