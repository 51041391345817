import React from 'react';

import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import LandingPageSecret from './pages/LandingPageSecret';
import ScreeningTest from './pages/ScreeningTest';
import MinuteSpeech from './pages/MinuteSpeech';
import CustomLogin from './pages/CustomLogin';

// import PermissionRoute from 'components/PermissionRoute';
// import PERMISSIONS from 'utils/permissions';

import {BrowserRouter as Router, Route} from 'react-router-dom';
import {EnvironmentBanner} from './components/EnvironmentBanner';

import {ENVIRONMENT} from './constants';

// import './App.scss';

const App = () => {
	// const auth = useSelector((state) => state.auth);

	return (
		<div className="PageWrapper">
			<EnvironmentBanner environment={ENVIRONMENT} />
			<Router>
				<Route path="/" exact component={LandingPage} />
				<Route path="/login/" component={Login} />
				<Route path="/custom-login" component={CustomLogin} />
				<Route path="/test/" component={ScreeningTest} />
				<Route path="/oneminutespeech/" component={MinuteSpeech} />

				{/* <Route
					path="/JJiisTezRREXXDSSERllliOR321Frrsaas/"
					component={LandingPageSecret}
				/> */}
			</Router>
		</div>
	);
};

export default App;
