import './styles.scss';
import React, {Component} from 'react';
import sendBase64Data from '../../api/sendBase64Data';
import getUserInfo from '../../api/getUserInfo';
import {begin} from '../../utils/beforeunload';
import clockSrc from './assets/clock.png';
const speechSettings = [
	{item: '', silence_delay: -0, min_decibels: -47},
	{item: 'har', silence_delay: -0, min_decibels: -50},
	{item: 'og', silence_delay: -0, min_decibels: -50},
	{item: 'til', silence_delay: 5, min_decibels: -50},
	{item: 'den', silence_delay: 5, min_decibels: -50},
	{item: 'med', silence_delay: 20, min_decibels: -50},
	{item: 'ikke', silence_delay: 50, min_decibels: -50},
	{item: 'vil', silence_delay: 5, min_decibels: -50},
	{item: 'seg', silence_delay: 12, min_decibels: -50},
	{item: 'hør', silence_delay: 10, min_decibels: -50},
	{item: 'bok', silence_delay: 5, min_decibels: -50},
	{item: 'hun', silence_delay: 10, min_decibels: -50},
	{item: 'være', silence_delay: 15, min_decibels: -50},
	{item: 'blir', silence_delay: 10, min_decibels: -50},
	{item: 'skal', silence_delay: 15, min_decibels: -50},
	{item: 'over', silence_delay: 15, min_decibels: -50},
	{item: 'hus', silence_delay: 20, min_decibels: -50},
	{item: 'eller', silence_delay: 10, min_decibels: -50},
	{item: 'andre', silence_delay: 10, min_decibels: -50},
	{item: 'bare', silence_delay: 10, min_decibels: -50},
	{item: 'konge', silence_delay: 10, min_decibels: -50},
	{item: 'mellom', silence_delay: 10, min_decibels: -50},

	{item: 'spise', silence_delay: 35, min_decibels: -50},
	{item: 'penn', silence_delay: 6, min_decibels: -50},
	{item: 'første', silence_delay: 70, min_decibels: -50},
	{item: 'gjøre', silence_delay: 20, min_decibels: -50},
	{item: 'skole', silence_delay: 20, min_decibels: -50},
	{item: 'norsk', silence_delay: 5, min_decibels: -50},
	{item: 'hvorfor', silence_delay: 50, min_decibels: -50},
	{item: 'store', silence_delay: 15, min_decibels: -50},
	{item: 'hvete', silence_delay: 50, min_decibels: -50},
	{item: 'godt', silence_delay: -0, min_decibels: -50},
	{item: 'flagg', silence_delay: -0, min_decibels: -50},
	{item: 'forteller', silence_delay: 50, min_decibels: -50},
	{item: 'tidligere', silence_delay: 50, min_decibels: -50},
	{item: 'rundt', silence_delay: 15, min_decibels: -50},
	{item: 'gjennom', silence_delay: 10, min_decibels: -50},
	{item: 'sjakk', silence_delay: 15, min_decibels: -50},
	{item: 'derfor', silence_delay: 120, min_decibels: -50},
	{item: 'hvordan', silence_delay: 40, min_decibels: -50},
	{item: 'allerede', silence_delay: 20, min_decibels: -50},
	{item: 'fortsatt', silence_delay: 80, min_decibels: -50},
	{item: 'samtidig', silence_delay: 60, min_decibels: -50},
	{item: 'viktig', silence_delay: 80, min_decibels: -50},
	{item: 'hvert', silence_delay: 20, min_decibels: -50},
	{item: 'mulig', silence_delay: -0, min_decibels: -50},
	{item: 'bjørn', silence_delay: -0, min_decibels: -50},
	{item: 'kjole', silence_delay: -0, min_decibels: -50},
	{item: 'kanskje', silence_delay: 80, min_decibels: -50},
	{item: 'desember', silence_delay: 50, min_decibels: -50},
	{item: 'vanskelig', silence_delay: 80, min_decibels: -50},
	{item: 'linjal', silence_delay: 20, min_decibels: -50},
	{item: 'skjorte', silence_delay: 120, min_decibels: -50},
	{item: 'kikkert', silence_delay: 120, min_decibels: -50},
	{item: 'hverandre', silence_delay: 50, min_decibels: -50},
	{item: 'kjøpe', silence_delay: 90, min_decibels: -50},
	{item: 'spørsmal', silence_delay: 40, min_decibels: -50},
	{item: 'akkurat', silence_delay: 60, min_decibels: -50},
	{item: 'egentelig', silence_delay: 80, min_decibels: -50},
	{item: 'keramikk', silence_delay: 10, min_decibels: -50},
	{item: 'tigger', silence_delay: 20, min_decibels: -50},
	{item: 'selskapet', silence_delay: 60, min_decibels: -50},
	{item: 'kylling', silence_delay: 5, min_decibels: -50},
	{item: 'autograf', silence_delay: 10, min_decibels: -50},
	{item: 'kiste', silence_delay: 80, min_decibels: -50},
	{item: 'hjort', silence_delay: 10, min_decibels: -50},
	{item: 'hjerte', silence_delay: -0, min_decibels: -50},
	{item: 'pennal', silence_delay: 50, min_decibels: -50},
	{item: 'lørdag', silence_delay: 50, min_decibels: -50},
	{item: 'gjær', silence_delay: 45, min_decibels: -50},
	{item: 'kakao', silence_delay: 15, min_decibels: -50},
	{item: 'spiseskje', silence_delay: 15, min_decibels: -50},
	{item: 'kjøtt', silence_delay: 25, min_decibels: -50},
	{item: 'utvikling', silence_delay: 20, min_decibels: -50},
	{item: 'elefant', silence_delay: -0, min_decibels: -50},
	{item: 'ryggsekk', silence_delay: 50, min_decibels: -50},
	{item: 'sjiraff', silence_delay: 10, min_decibels: -50},
	{item: 'hjort', silence_delay: 20, min_decibels: -50},
	{item: 'gardinene', silence_delay: 60, min_decibels: -50},
	{item: 'leverpostei', silence_delay: 30, min_decibels: -50},
	{item: 'helikopter', silence_delay: 30, min_decibels: -50},
	{item: 'rabarbra', silence_delay: -0, min_decibels: -50},
	{item: 'tyggegummi', silence_delay: 30, min_decibels: -50},
	{item: 'forskjellige', silence_delay: 10, min_decibels: -50},
	{item: 'trampoline', silence_delay: 40, min_decibels: -50},
	{item: 'proteiner', silence_delay: 40, min_decibels: -50},
	{item: 'musikk', silence_delay: 20, min_decibels: -50},
	{item: 'helomvending', silence_delay: 30, min_decibels: -50},
	{item: 'slikkepott', silence_delay: 30, min_decibels: -50},
	{item: 'situasjonen', silence_delay: 20, min_decibels: -50},
	{item: 'ambivalent', silence_delay: 5, min_decibels: -50},
	{item: 'kravstor', silence_delay: 5, min_decibels: -50},
	{item: 'segne', silence_delay: 20, min_decibels: -50},
	{item: 'binders', silence_delay: 25, min_decibels: -50},
	{item: 'fluelort', silence_delay: 15, min_decibels: -50},
	{item: 'pekefinger', silence_delay: 15, min_decibels: -50},
	{item: 'landsforræder', silence_delay: 15, min_decibels: -50},
	{item: 'bilmekaniker', silence_delay: 15, min_decibels: -50},
	{item: 'skjære', silence_delay: 20, min_decibels: -50},
	{item: 'perforere', silence_delay: 160, min_decibels: -50},
	{item: 'sammensetning', silence_delay: 25, min_decibels: -50},
	{item: 'hallusinasjon', silence_delay: 25, min_decibels: -50},
	{item: 'spekulasjon', silence_delay: 25, min_decibels: -50},
	{item: 'kildesortering', silence_delay: 25, min_decibels: -50},
	{item: 'akvakultur', silence_delay: 25, min_decibels: -50},
	{item: 'avdramatisere', silence_delay: 25, min_decibels: -50},
	{item: 'dyskalkuli', silence_delay: 125, min_decibels: -50},
	{item: 'makulering', silence_delay: 25, min_decibels: -50},
	{item: 'ukulele', silence_delay: 125, min_decibels: -50},
	{item: 'klinkekule', silence_delay: 125, min_decibels: -50},
	{item: 'beredskapsplan', silence_delay: 125, min_decibels: -50},
	{item: 'byggeindustri', silence_delay: 125, min_decibels: -50},
	{item: 'kjekjøtt', silence_delay: 125, min_decibels: -50},
	{item: 'skjermdump', silence_delay: 125, min_decibels: -50},
	{item: 'lakseoppdrett', silence_delay: 125, min_decibels: -50},
	{item: 'utdanningsprogram', silence_delay: 125, min_decibels: -50},
	{item: 'beskjedent', silence_delay: 125, min_decibels: -50},
	{item: 'riksmeklingsmannen', silence_delay: 225, min_decibels: -50},
];
const oneMinuteTasks = [
	'har',
	'bok',
	'til',
	'den',
	'og',
	'med',
	'ikke',
	'vil',
	'seg',
	'hør',
	'hun',
	'blir',
	'skal',
	'over',
	'være',
	'hus',
	'eller',
	'andre',
	'bare',
	'kroner',
	'konge',
	'mellom',
	'spise',
	'penn',
	'første',
	'gjøre',
	'skole',
	'norsk',
	'hvorfor',
	'store',
	'hvete',
	'godt',
	'flagg',
	'forteller',
	'tidligere',
	'rundt',
	'gjennom',
	'sjakk',
	'derfor',
	'hvordan',
	'allerede',
	'fortsatt',
	'samtidig',
	'viktig',
	'hvert',
	'mulig',
	'bjørn',
	'kjole',
	'kanskje',
	'desember',
	'vanskelig',
	'linjal',
	'skjorte',
	'kikkert',
	'hverandre',
	'kjøpe',
	'spørsmål',
	'akkurat',
	'egentlig',
	'keramikk',
	'tigger',
	'selskapet',
	'kylling',
	'autograf',
	'kiste',
	'hjort',
	'skjedde',
	'hjerte',
	'pennal',
	'lørdag',
	'gjær',
	'kakao',
	'spiseskje',
	'kjøtt',
	'utvikling',
	'elefant',
	'ryggsekk',
	'sjiraff',
	'hjort',
	'gardinene',

	'leverpostei',
	'helikopter',
	'rabarbra',
	'helomvending',
	'tyggegummi',
	'forskjellige',
	'trampoline',
	'proteiner',
	'fortau',
	'musikk',

	'slikkepott',
	'situasjonen',
	'ambivalent',
	'kravstor',
	'segne',
	'binders',
	'fluelort',
	'pekefinger',
	'landsforræder',
	'bilmekaniker',
	'skjære',
	'perforere',
	'sammensetning',
	'hallusinasjon',
	'spekulasjon',
	'kildesortering',
	'akvakultur',
	'avdramatisere',
	'dyskalkuli',
	'makulering',
	'ukulele',
	'klinkekule',
	'beredskapsplan',
	'byggeindustri',
	'kjekjøtt',
	'skjermdump',
	'lakseoppdrett',
	'utdanningsprogram',
	'beskjedent',
	'riksmeklingsmannen',
];
var audioChunks = [];
const audioBits = [];
const mainArray = [];
var silence = true;
var timeLimit = false;
var counter = 10;
var silence_delay = -0;
var min_decibels = -40;
let data = null;
let keyPressAllowed = true;
class MinuteSpeechTest extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 0,
			currentItem: speechSettings[counter].item,
			timer: 0,
			currentPool: 10,
			currentIndex: 0,
			currentTasks: [],
			isLoggedIn: false,
		};
		this.detectSilence = this.detectSilence.bind(this);
	}
	detectSilence = (
		stream,
		rec,
		onSoundEnd = (_) => {},
		onSoundStart = (_) => {}
	) => {
		setTimeout(() => {
			timeLimit = true;
			if (rec.state !== 'inactive') {
				rec.stop();
			}

			this.gatherChunkedAudio(rec);
		}, 61000);
		const ctx = new AudioContext();
		const analyser = ctx.createAnalyser();
		const streamNode = ctx.createMediaStreamSource(stream);
		streamNode.connect(analyser);
		analyser.minDecibels = min_decibels;

		data = new Uint8Array(analyser.frequencyBinCount);
		let silence_start = performance.now();
		let triggered = false;
		rec.start();
		function loop(time) {
			rec.ondataavailable = (e) => {
				audioChunks.push(e.data);
			};
			rec.onstop = (e) => {
				if (!timeLimit) {
					if (audioChunks.length) audioBits.push({audioChunks});
					audioChunks = [];
					rec.start();
				}
			};
			rec.onstart = (e) => {
				console.log(' started ');
			};
		}
		loop();
	};

	onSilence = (analyser) => {
		console.log(analyser.minDecibels);
		silence = true;
		counter = counter + 1;
		analyser.minDecibels = speechSettings[counter].min_decibels;
		data = new Uint8Array(analyser.frequencyBinCount);
		silence_delay = speechSettings[counter]['silence_delay'];
		this.setState({
			currentItem: speechSettings[counter].item,
		});
	};
	onSpeak = () => {
		silence = false;
	};
	setClock() {
		if (this.state.timer < 60) this.setState({timer: this.state.timer + 1});
		//delay implementation based on the grade:
	}
	enableKeyPressAgain() {
		if (timeLimit) return;
		let pressDelay;
		switch (this.state.currentIndex) {
			case 0:
				pressDelay = 2500;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 10:
				pressDelay = 2500;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 20:
				pressDelay = 3000;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 30:
				pressDelay = 3200;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 40:
				pressDelay = 3500;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 50:
				pressDelay = 3600;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 60:
				pressDelay = 4000;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 70:
				pressDelay = 4100;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 80:
				pressDelay = 4200;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 90:
				pressDelay = 4500;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 100:
				pressDelay = 4500;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 110:
				pressDelay = 5000;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
			case 120:
				pressDelay = 5200;
				setTimeout(() => {
					keyPressAllowed = true;
				}, pressDelay);
				break;
		}
	}

	componentDidMount() {
		getUserInfo().then((response) => {
			if (response.ok) {
				this.setState({
					isLoggedIn: true,
				});
				begin();
			} else {
				window.location.href = `/`;
			}
		});
		setInterval(() => {
			this.setClock();
		}, 1000);

		var device = navigator.mediaDevices.getUserMedia({
			audio: {channelCount: 1, sampleRate: 48000},
		});
		device.then((stream) => {
			var rec = new MediaRecorder(stream, {
				mimeType: 'audio/webm;codecs=opus',
			});

			/* this.detectSilence(stream, rec, this.onSilence, this.onSpeak); commenting out for this version but will be used for other subtests*/
			setTimeout(() => {
				timeLimit = true;
				if (rec.state !== 'inactive') {
					rec.stop();
				}
				if (audioChunks.length) audioBits.push({audioChunks});
				audioChunks = [];

				this.gatherChunkedAudio(rec);
			}, 61000);

			rec.start();
			window.addEventListener('keyup', (event) => {
				if (keyPressAllowed) {
					keyPressAllowed = false;
					setTimeout(() => {
						if (rec.state !== 'inactive') {
							rec.stop();
						}
						counter = counter + 10;

						this.setState({
							currentIndex: this.state.currentIndex + 10,
							currentPool: this.state.currentPool + 10,
						});

						rec.start();
					}, 400);
					this.enableKeyPressAgain();
				}
			});

			rec.ondataavailable = (e) => {
				audioChunks.push(e.data);
			};
			rec.onstop = (e) => {
				if (!this.state.timeOut) {
					audioBits.push({audioChunks});
					audioChunks = [];
				}
			};
			rec.onstart = (e) => {
				console.log('recording started again');
			};
		});
	}
	gatherChunkedAudio(rec) {
		if (rec.state !== 'inactive') {
			rec.stop();
		}
		rec.ondataavailable = (e) => {
			audioChunks = [];
			audioChunks.push(e.data);
			this.processChunkedAudio('finalAudioChunk');
		};
	}
	processChunkedAudio(lastChunk) {
		let baseArray = [];
		let base64Obj = {};
		let promises = [];

		if (audioChunks.length) audioBits.push({audioChunks});
		const arr = [];

		const chunkSize = audioBits.length - 1;
		for (let i = 0; i < audioBits.length; i++) {
			arr.push(audioBits[i].audioChunks[0]);
			var blobdata = new Blob(audioBits[i].audioChunks, {
				type: 'audio/webm',
			});
		}
		var blob = new Blob(arr, {
			type: 'audio/webm',
		});

		for (let i = 0; i < audioBits.length; i++) {
			let blobArray = new Blob(audioBits[i].audioChunks, {
				type: 'audio/webm',
			});
			let filePromise = new Promise((resolve) => {
				let reader = new FileReader();
				reader.readAsDataURL(blobArray);
				reader.onload = () => resolve(reader.result);
			});
			promises.push(filePromise);
		}
		Promise.all(promises).then((fileContents) => {
			const baseArray = {
				baseData: fileContents,
				age: this.props.age,
				grade: this.props.grade,
			};
			this.sendEncodedData(baseArray);
		});

		/* var audio = document.getElementById('audio');
		var mainAudio = document.createElement('audio');
		mainAudio.setAttribute('controls', 'controls');
		audio.appendChild(mainAudio);

		mainAudio.innerHTML =
			'<source src = "' +
			URL.createObjectURL(blob) +
			'"type="audio/webm"/>'; */
	}
	//modify the memetype here
	sendEncodedData(base64Data) {
		keyPressAllowed = false;
		this.props.onSendDataForTranscription(base64Data);
	}

	showTaskes(item, index) {
		return (
			<p key={index} className={`items`}>
				{item}
			</p>
		);
	}
	render() {
		const {currentStep, timer} = this.state;

		let items = [];
		for (let i = this.state.currentIndex; i < this.state.currentPool; i++) {
			items.push(oneMinuteTasks[i]);
		}

		return (
			<div className="TestWrapper2">
				{/* <div className="audio" id="audio"></div> */}
				<br />
				<div className="lit-speech-screening-counter">
					<p key={1} className={`counter`}>
						{`${counter} av 120`}
					</p>
				</div>
				<div className="middle-pool-container">
					<div className="middle-item-container">
						{items.map((item, index) => {
							{
								return this.showTaskes(item, index);
							}
						})}
					</div>
				</div>
				<div className="lit-speech-screening-timer">
					<p key={timer} className={`timer`}>
						<span className="clockImg">
							<img src={clockSrc} alt="paris"></img>
							{timer}
						</span>
					</p>
				</div>
			</div>
		);
	}
}
export default MinuteSpeechTest;
