//TODO-SIMEN REPLACE ALL THESE WITH LOCALIZED SOUNDS
import React from 'react';
import modilSound from '../../../../assets/sounds/youth/se/3 05 Tulleord Vuks er feil.mp3';
import falkSound from '../../../../assets/sounds/youth/se/3 05 Tulleord Falk er riktig.mp3';
import vuksSound from '../../../../assets/sounds/youth/se/3 05 Tulleord Vuks er feil.mp3';
import flyntSound from '../../../../assets/sounds/youth/se/3 05 Tulleord Vuks er feil.mp3';
import gjalteSound from '../../../../assets/sounds/youth/se/3 05 Tulleord Vuks er feil.mp3';

import vorSound from '../../../../assets/sounds/youth/se/3 09 Tulleord Vor er riktig.mp3';
import blastellSound from '../../../../assets/sounds/youth/se/3 10 Tulleord Blastell er feil.mp3';
import peanoSound from '../../../../assets/sounds/youth/se/3 10 Tulleord Blastell er feil.mp3';
import brillterSound from '../../../../assets/sounds/youth/se/3 10 Tulleord Blastell er feil.mp3';
import knainSound from '../../../../assets/sounds/youth/se/3 10 Tulleord Blastell er feil.mp3';

import example from '../../../../assets/sounds/youth/se/3 02 Tulleord Sgo.mp3';
import yourTurn from '../../../../assets/sounds/youth/se/3 03 Tulleord Na er det din tur.mp3';
import startTheTest from '../../../../assets/sounds/youth/se/3 13 Tulleord Starter testen.mp3';

export const exampleSound = example;
export const yourTurnSound = yourTurn;
export const startTheTestSound = startTheTest;

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'späl',
		},
		{
			isCorrect: true,
			word: 'syckel',
		},
		{
			word: 'cholkad',
		},
		{
			word: 'ösk',
		},
		{
			word: 'truligen',
		},
	],
};

export const assignments = [
	{
		id: 2,
		choices: [
			{
				word: 'modil',
				comment: 'Modil är fel,',
				sound: modilSound,
			},
			{
				word: 'vuks',
				comment: 'Vuks är fel,',
				sound: vuksSound,
			},
			{
				word: 'fålk',
				comment: 'Fålk är helt rätt.',
				sound: falkSound,
			},
			{
				word: 'flynta',
				comment: 'Flynta är fel.',
				sound: flyntSound,
			},
			{
				word: 'skinnlad',
				comment: 'Gjalte är fel.',
				sound: gjalteSound,
			},
		],
	},
	{
		id: 3,
		choices: [
			{
				word: 'vuksen',
				comment: 'Vorr är rätt',
				sound: vorSound,
			},
			{
				word: 'korribor',
				comment: 'korribor är fel.',
				sound: blastellSound,
			},
			{
				word: 'peano',
				comment: 'Peano är fel,',
				sound: peanoSound,
			},
			{
				word: 'möbbel',
				comment: 'Möbbel är fel.',
				sound: knainSound,
			},
			{
				word: 'älak',
				comment: 'älak är fel,',
				sound: brillterSound,
			},
		],
	},
];

export const hereYouSeeExample = [
	{
		textData: (
			<span>
				Här ser du{' '}
				<span style={{color: 'Gold'}}>
					<b>späl</b>
				</span>
				,{' '}
				<span style={{color: 'Gold'}}>
					<b>syckel</b>
				</span>
				,{' '}
				<span style={{color: 'Gold'}}>
					<b>cholkad</b>
				</span>
				,{' '}
				<span style={{color: 'Gold'}}>
					<b>ösk</b>
				</span>{' '}
				och{' '}
				<span style={{color: 'Gold'}}>
					<b>truligen</b>
				</span>
				. Det korrekta svaret är «
				<span style={{color: 'Gold'}}>
					<b>syckel</b>
				</span>
				» som i cykel. I detta exempel ser de andra orden nästan
				korrekta ut, men när du läser dem så som de är stavade så blir
				det tydligt att de är påhittade ord.
			</span>
		),
	},
];
