import {IS_IN_PRODUCTION, LANGUAGE} from '../constants';
import LocalizedStrings from 'react-localization';
import no from '../languages/no';
import nn from '../languages/nn';
import se from '../languages/se';
import de from '../languages/de';

const strings = new LocalizedStrings({
	no: no,
	nn: nn,
	se: se,
	de: de,
});

const DEBUG_LANGUAGES = false;
if (!IS_IN_PRODUCTION && DEBUG_LANGUAGES) {
	const mainLanguage = no;
	const mainLanguageKeys = Object.keys(mainLanguage);
	const otherLanguages = [nn, se, de];

	for (let i = 0; i < otherLanguages.length; i++) {
		const language = otherLanguages[i];
		const languageKeys = Object.keys(language);

		for (const mainLanguageKey of mainLanguageKeys) {
			if (languageKeys.indexOf(mainLanguageKey) === -1) {
				console.warn(
					`${mainLanguageKey} missing in otherLanguage with index: ${i}`
				);
			}
		}
	}
}
strings.setLanguage(LANGUAGE);

export default strings;
