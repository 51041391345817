const beforeunloadHandler = (e) => {
  const dialogText = 'Hvis du går ut av testen vil du trenge en helt ny kode, samt starte på nytt!';
  e.returnValue = dialogText;
  return dialogText;
};

const BEFORE_UNLOAD = 'beforeunload';

export function begin() {
	window.addEventListener(BEFORE_UNLOAD, beforeunloadHandler);
}

export function end() {
	window.removeEventListener(BEFORE_UNLOAD, beforeunloadHandler);
}
