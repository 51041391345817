import {SET_TEST_STEP_ANSWERS} from '../actions/index';

import {updateObject} from '../utils/reducerUtils';

import {TEST_STEPS} from '../constants';

const defaultTestStepAnswers = {
	[TEST_STEPS.ORDDIKTAT]: {},
	[TEST_STEPS.ORDKJEDETESTEN]: {},
	[TEST_STEPS.TULLEORDTESTEN]: {},
	[TEST_STEPS.SKRIVEHASTIGHET]: {},
	[TEST_STEPS.LESEPRØVEN]: {},
	[TEST_STEPS.ORDFORSTÅELSE]: {},
};

const setTestStepAnswers = (state, action) => {
	if (!state.hasOwnProperty(action.step)) {
		throw new Error(`Invalid test step: ${action.step}`);
	}

	return updateObject(state, {
		[action.step]: action.answers,
	});
};

const testStepAnswers = (state = defaultTestStepAnswers, action) => {
	switch (action.type) {
		case SET_TEST_STEP_ANSWERS:
			return setTestStepAnswers(state, action);

		default:
			return state;
	}
};

export default testStepAnswers;
