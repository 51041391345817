import getFetchOptions from '../utils/getFetchOptions';
import {ENDPOINT_URL} from '../constants';

export const loginNoAuth = (data) => {
	return fetch(
		`${ENDPOINT_URL}/verifyNoAuth`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify(data),
		})
	).then((response) => {
		if (response.ok) {
			return response.json();
		}
		return Promise.reject();
	});
};

export default loginNoAuth;
