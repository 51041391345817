import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import strings from '../../utils/strings';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './styles.scss';

import submitTestUserInfo from '../../api/submitTestUserInfo';

import {goToNextStep} from '../../actions/index';
import {setTestCandidateGrade} from '../../actions/index';

import {updateObject} from '../../utils/reducerUtils';

//import {TEST_USER_INFO_LABELS} from '../../constants';

import BooleanRadio from '../../components/BooleanRadio';
import {LANGUAGE as LANGUAGE_VERSION} from '../../constants';

moment.locale('nb');

const TEST_USER_INFO_LABELS = {
	gender: strings.gender,
	juniorHigh: strings.juniorHigh,
	highSchool: strings.highSchool,
	bachelor: strings.bachelor,
	master: strings.master,
	phd: strings.phd,
	other: strings.other,
	youthProgram: strings.youthProgram,
	accompanyingMeasures: strings.accompanyingMeasures,
	workPreparatoryTraining: strings.workPreparatoryTraining,
	norwegian: strings.norwegian,
	sapmi: strings.sapmi,
	russian: strings.russian,
	english: strings.english,
	arabic: strings.arabic,
	polish: strings.polish,
	kurdish: strings.kurdish,
	spanish: strings.spanish,
	tamil: strings.tamil,
	thai: strings.thai,
	turkish: strings.turkish,
	yes: strings.yes,
	no: strings.no,
	doesNotKnow: strings.doesNotKnow,
	youthGrade8: strings.youthGrade8,
	youthGrade9: strings.youthGrade9,
	youthGrade10: strings.youthGrade10,
};

const languages = [
	'norwegian',
	'sapmi',
	'russian',
	'english',
	'arabic',
	'polish',
	'kurdish',
	'tamil',
	'thai',
	'turkish',
	'other',
];

const selfRatings = [1, 2, 3, 4, 5, 6]; // eslint-disable-line
const youthGrades = [8, 9, 10]; // eslint-disable-line
const threeOptions = ['no', 'yes', 'doesNotKnow'];

const GENDER = 'gender';
const EMAIL = 'email';
const PHONE = 'phone';
const AGE = 'age';
const EDUCATION = 'education';
const YOUTH_GRADE = 'youthGrade';

const HOME_LANGUAGE = 'homeLanguage';
const GREW_UP_IN_BILINGUAL_HOME = 'grewUpInBilingualHome';
const FIRST_LANGUAGE = 'firstLanguage';
const OTHER_FIRST_LANGUAGE = 'otherFirstLanguage';
const SECOND_LANGUAGE = 'secondLanguage';
const OTHER_SECOND_LANGUAGE = 'otherSecondLanguage';
const LANGUAGE = 'language';
const TROUBLE_REMEMBERING_WHICH_WORD_TO_SAY =
	'troubleRememberingWhichWordToSay';
const SHUFFLES_WORDS_WHEN_SPEAKING = 'shufflesWordsWhenSpeaking';
const SPENDS_LONG_TIME_FORMULATING_CORRECTLY =
	'spendsLongTimeFormulatingCorrectly';
const HAS_BEEN_SUSPICIONS_OF_DIFFICULTIES = 'hasBeenSuspicionsOfDifficulties';
const SUSPECTS_TO_HAVE_DIFFICULTIES = 'suspectsToHaveDifficulties';
const READING_AND_WRITING = 'readingAndWriting';
const DIFFICULTIES_WITH = 'difficultiesWith';
const READING_WORDS_CORRECTLY = 'readingWordsCorrectly';
const READ_LONG_TEXTS = 'readLongTexts';
const REMEMBERING_WHAT_YOU_HAVE_READ = 'rememberingWhatYouHaveRead';
const WRITE_WORDS_PROPERLY = 'writeWordsProperly';
const GETTING_THOUGHTS_ON_PAPER = 'gettingThoughtsOntoPaper';
const READING_AND_WRITING_ENGLISH = 'readingAndWritingEnglish';
const SUSPECTS_TO_HAVE_DYSLEXIA = 'suspectsToHaveDyslexia';
const MATH = 'math';
const HAS_DIFFICULTIES_WITH_MATH_OR_CALCULATION =
	'hasDifficultiesWithMathOrCalculation';
const HAS_BEEN_ASSESSED_FOR_DIFFICULTIES = 'hasBeenAssessedForDifficulties';
const SUSPECTS_TO_HAVE_DYSCALCULIA = 'suspectsToHaveDyscalculia';
const ADHD = 'adhd';
const HAS_DIFFICULTIES_WITH_CONCENTRATION_AND_STAMINA =
	'hasDifficultiesWithConcentrationAndStamina';
const EASILY_BORED_AND_RESTLESS = 'easilyBoredAndRestless';
const HAS_DIAGNOSIS = 'hasDiagnosis';
const SUSPECTS_TO_HAVE_ADD_OR_ADHD = 'suspectsToHaveAddOrAdhd';
const CLOSE_RELATIVE_HAS_DIFFICULTIES = 'closeRelativeHasDifficulties';
const SELF_RATING = 'selfRating';

const now = moment();

const availableLanguages = {
	no: [
		{key: 'no', name: 'Norsk Bokmål'},
		{key: 'nn', name: 'Norsk Nynorsk'},
	],
	se: [{key: 'se', name: 'Svenska'}],
	de: [{key: 'de', name: 'Deutsch'}],
};

class Introduksjon extends React.Component {
	constructor(props) {
		super(props);
		strings.setLanguage(strings.getLanguage());

		this.state = {
			showLengthyIntro: props.includeLongAnamnese,
			submitEnabled: true,
			hasSelectedLanguage:
				availableLanguages[LANGUAGE_VERSION].length === 1, //Autoselects language if only one is available
			isFormValid: false,
			birthDate: moment().subtract(10, 'year').toDate(),
			candidateGrade: null,
			testUser: {
				gender: '',
				email: '',
				phone: '',
				age: {
					years: 0,
					months: 0,
				},
				education: {
					youthGrade: 0,
				},
				homeLanguage: {
					grewUpInBilingualHome: '',

					/* if grewUpInBilingualHome */
					firstLanguage: '', // 'norwegian', 'sapmi', 'russian', 'english', 'arabic', 'polish', 'kurdish', 'tamil', 'thai', 'turkish', 'other'
					otherFirstLanguage: '',
					secondLanguage: '', // 'norwegian', 'sapmi', 'russian', 'english', 'arabic', 'polish', 'kurdish', 'tamil', 'thai', 'turkish', 'other'
					otherSecondLanguage: '',
				},
				language: {
					troubleRememberingWhichWordToSay: '',
					shufflesWordsWhenSpeaking: '',
					spendsLongTimeFormulatingCorrectly: '',
					hasBeenSuspicionsOfDifficulties: '',
					hasBeenAssessedForDifficulties: '',

					/* if hasBeenAssessedForDifficulties */
					hasDiagnosis: '',

					/* if !hasBeenAssessedForDifficulties */
					suspectsToHaveDifficulties: '',

					closeRelativeHasDifficulties: '', // 'yes', 'no', 'doesNotKnow'
					selfRating: '', // 1, 2, 3, 4, 5, 6
				},
				readingAndWriting: {
					difficultiesWith: {
						readingWordsCorrectly: '',
						readLongTexts: '',
						rememberingWhatYouHaveRead: '',
						writeWordsProperly: '',
						gettingThoughtsOntoPaper: '',
						readingAndWritingEnglish: '',
					},
					hasBeenAssessedForDifficulties: '',

					/* if hasBeenAssessedForDifficulties */
					hasDiagnosis: '',

					/* if !hasBeenAssessedForDifficulties */
					suspectsToHaveDyslexia: '',

					closeRelativeHasDifficulties: '', // 'yes', 'no', 'doesNotKnow'
					selfRating: '', // 1, 2, 3, 4, 5, 6
				},
				math: {
					hasDifficultiesWithMathOrCalculation: '',
					hasBeenAssessedForDifficulties: '',

					/* if hasBeenAssessedForDifficulties */
					hasDiagnosis: '',

					/* if !hasBeenAssessedForDifficulties */
					suspectsToHaveDyscalculia: '',

					closeRelativeHasDifficulties: '', // 'yes', 'no', 'doesNotKnow'
					selfRating: '', // 1, 2, 3, 4, 5, 6
				},
				adhd: {
					hasDifficultiesWithConcentrationAndStamina: '',
					easilyBoredAndRestless: '',
					hasBeenAssessedForDifficulties: '',

					/* if hasBeenAssessedForDifficulties */
					hasDiagnosis: '',

					/* if !hasBeenAssessedForDifficulties */
					suspectsToHaveAddOrAdhd: '',

					closeRelativeHasDifficulties: '', // 'yes', 'no', 'doesNotKnow'
					selfRating: '', // 1, 2, 3, 4, 5, 6
				},
			},
		};
		this.onSelectLanguage = this.onSelectLanguage.bind(this);
	}

	updateTestUser(categoryKey, key, value) {
		const {testUser} = this.state;

		if (categoryKey) {
			const category = updateObject(testUser[categoryKey], {
				[key]: value,
			});

			this.setState({
				testUser: updateObject(testUser, {
					[categoryKey]: category,
				}),
			});
		} else {
			this.setState({
				testUser: updateObject(testUser, {
					[key]: value,
				}),
			});
		}
	}

	onSelectLanguage(lang) {
		strings.setLanguage(lang);
		this.setState({
			hasSelectedLanguage: true,
		});
	}

	render() {
		const {onGoToNextStep, onSetTestCandidateGrade} = this.props;
		const genders = [
			{
				label: strings.female,
				value: 'f',
			},
			{
				label: strings.male,
				value: 'm',
			},
		];
		const {
			isFormValid,
			birthDate,
			testUser,
			hasSelectedLanguage,
		} = this.state;

		const {
			email,
			phone,
			education,
			homeLanguage,
			language,
			readingAndWriting,
			math,
			adhd,
			gender,
		} = testUser;

		const {difficultiesWith} = readingAndWriting;
		const yearsToSubtract = 5;
		let formRef;

		return !hasSelectedLanguage ? (
			<div className="lit-test-intro">
				<h1>{strings.chooseLanguage}</h1>
				{availableLanguages[LANGUAGE_VERSION].map((lang) => (
					<button
						key={lang.key}
						className="lit-test-intro-language-button"
						onClick={() => this.onSelectLanguage(lang.key)}
					>
						{lang.name}
					</button>
				))}
			</div>
		) : (
			<div className="lit-test-intro">
				<h1 className="lit-page-title">{strings.welcomeToTest}</h1>
				<div className="lit-intro">
					<p>{strings.introInstructions}</p>
					<form
						className="pre-test-form"
						onChange={() => {
							const formValidity = formRef.checkValidity();

							if (isFormValid !== formValidity) {
								this.setState({
									isFormValid: formValidity,
								});
							}
						}}
						onSubmit={(event) => {
							event.preventDefault();
							if (!this.state.submitEnabled) {
								return;
							}
							this.setState({
								submitEnabled: false,
							});
							submitTestUserInfo(testUser)
								.then(onGoToNextStep)
								.catch((err) => {
									console.log(err);
									this.setState({
										submitEnabled: true,
									});
								});
						}}
						ref={(ref) => {
							formRef = ref;
						}}
					>
						{this.state.showLengthyIntro && (
							<div>
								<h2>{strings.general}</h2>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.gender}
									</label>
									<div className="lit-input__field lit-radios">
										{genders.map((genderOption) => {
											const {
												label: genderLabel,
												value,
											} = genderOption;

											return (
												<span
													className="lit-radio"
													key={value}
												>
													<input
														checked={
															value === gender
														}
														className="lit-radio__field"
														id={`gender${value}`}
														name="gender"
														onChange={() => {
															this.updateTestUser(
																null,
																GENDER,
																value
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`gender${value}`}
													>
														{genderLabel}
													</label>
												</span>
											);
										})}
									</div>
								</div>
							</div>
						)}
						<div className="lit-input inline">
							<label
								className="lit-input__label wider"
								htmlFor=""
							>
								{strings.birthDate}
							</label>
							<DatePicker
								className="lit-input__field"
								dropdownMode="select"
								inline
								maxDate={moment()
									.subtract(yearsToSubtract, 'years')
									.toDate()}
								name="age"
								onChange={(value) => {
									const numberOfMonths = 12;
									this.setState(
										{
											birthDate: value,
										},
										() => {
											const age = {
												years: now.diff(value, 'years'),
												months:
													now.diff(value, 'months') %
													numberOfMonths,
											};

											this.updateTestUser(null, AGE, age);
										}
									);
								}}
								placeholderText={strings.clickToSelectDate}
								required
								selected={birthDate}
								adjustDateOnChange
								showYearDropdown
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wider"
								htmlFor="email"
							>
								{strings.email}
							</label>
							<input
								className="lit-input__field"
								id="email"
								name="email"
								onChange={(event) => {
									const value = event.target.value;
									this.updateTestUser(null, EMAIL, value);
								}}
								placeholder={strings.emailPlaceholder}
								type="email"
								value={email}
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wider"
								htmlFor="phone"
							>
								{strings.telephone}
							</label>
							<input
								className="lit-input__field"
								id="phone"
								name="phone"
								onChange={(event) => {
									const value = event.target.value;
									this.updateTestUser(null, PHONE, value);
								}}
								placeholder={strings.telephonePlaceholder}
								type="tel"
								value={phone}
							/>
						</div>

						<h2>{strings.education}</h2>
						<div className="lit-input">
							<label className="lit-input__label wider">
								{strings.questionYouthGrade}
							</label>
							<div className="lit-input__field lit-radios">
								{youthGrades.map((grade) => {
									return (
										<span className="lit-radio" key={grade}>
											<input
												checked={
													grade ===
													education[YOUTH_GRADE]
												}
												className="lit-radio__field"
												id={`youthGrade${grade}`}
												name="grade"
												onChange={() => {
													this.setState({
														candidateGrade: grade,
													});
													onSetTestCandidateGrade(
														grade
													);
													this.updateTestUser(
														EDUCATION,
														YOUTH_GRADE,
														grade
													);
												}}
												required
												type="radio"
											/>
											<label
												className="lit-radio__label"
												htmlFor={`youthGrade${grade}`}
											>
												{
													TEST_USER_INFO_LABELS[
														'youthGrade' + grade
													]
												}
											</label>
										</span>
									);
								})}
							</div>
						</div>
						{this.state.showLengthyIntro && (
							<div>
								<h2>{strings.homeLanguage}</h2>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.questionBilingualHome}
									</label>
									<BooleanRadio
										name={GREW_UP_IN_BILINGUAL_HOME}
										onChange={(value) => {
											this.updateTestUser(
												HOME_LANGUAGE,
												GREW_UP_IN_BILINGUAL_HOME,
												value
											);
										}}
										value={
											homeLanguage[
												GREW_UP_IN_BILINGUAL_HOME
											]
										}
									/>
								</div>
								{homeLanguage[GREW_UP_IN_BILINGUAL_HOME] ===
									true && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{strings.questionFirstLanguage}
											</label>
											<div className="lit-input__field lit-radios language-inputs">
												{languages.map((language) => {
													return (
														<span
															className="lit-radio"
															key={language}
														>
															<input
																checked={
																	language ===
																	homeLanguage[
																		FIRST_LANGUAGE
																	]
																}
																className="lit-radio__field"
																id={`firstLanguage${language}`}
																name="firstLanguage"
																onChange={() => {
																	this.updateTestUser(
																		HOME_LANGUAGE,
																		FIRST_LANGUAGE,
																		language
																	);
																}}
																required
																type="radio"
															/>
															<label
																className="lit-radio__label"
																htmlFor={`firstLanguage${language}`}
															>
																{
																	TEST_USER_INFO_LABELS[
																		language
																	]
																}
															</label>
														</span>
													);
												})}
											</div>
										</div>
										{homeLanguage[FIRST_LANGUAGE] ===
											'other' && (
											<div className="lit-input inline">
												<label
													className="lit-input__label wider"
													htmlFor={
														OTHER_FIRST_LANGUAGE
													}
												>
													{strings.otherFirstLanguage}
												</label>
												<input
													className="lit-input__field"
													id={OTHER_FIRST_LANGUAGE}
													name={OTHER_FIRST_LANGUAGE}
													onChange={(event) => {
														const value =
															event.target.value;
														this.updateTestUser(
															HOME_LANGUAGE,
															OTHER_FIRST_LANGUAGE,
															value
														);
													}}
													placeholder={
														strings.languagePlaceholder
													}
													required
													type="text"
													value={
														homeLanguage[
															OTHER_FIRST_LANGUAGE
														]
													}
												/>
											</div>
										)}
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{strings.questionSecondLanguage}
											</label>
											<div className="lit-input__field lit-radios language-inputs">
												{languages.map((language) => {
													return (
														<span
															className="lit-radio"
															key={language}
														>
															<input
																checked={
																	language ===
																	homeLanguage[
																		SECOND_LANGUAGE
																	]
																}
																className="lit-radio__field"
																id={`secondLanguage${language}`}
																name="secondLanguage"
																onChange={() => {
																	this.updateTestUser(
																		HOME_LANGUAGE,
																		SECOND_LANGUAGE,
																		language
																	);
																}}
																required
																type="radio"
															/>
															<label
																className="lit-radio__label"
																htmlFor={`secondLanguage${language}`}
															>
																{
																	TEST_USER_INFO_LABELS[
																		language
																	]
																}
															</label>
														</span>
													);
												})}
											</div>
										</div>
										{homeLanguage[SECOND_LANGUAGE] ===
											'other' && (
											<div className="lit-input inline">
												<label
													className="lit-input__label wider"
													htmlFor={
														OTHER_SECOND_LANGUAGE
													}
												>
													{
														strings.otherSecondLanguage
													}
												</label>
												<input
													className="lit-input__field"
													id={OTHER_SECOND_LANGUAGE}
													name={OTHER_SECOND_LANGUAGE}
													onChange={(event) => {
														const value =
															event.target.value;
														this.updateTestUser(
															HOME_LANGUAGE,
															OTHER_SECOND_LANGUAGE,
															value
														);
													}}
													placeholder={
														strings.languagePlaceholder
													}
													required
													type="text"
													value={
														homeLanguage[
															OTHER_SECOND_LANGUAGE
														]
													}
												/>
											</div>
										)}
									</div>
								)}
							</div>
						)}

						{this.state.showLengthyIntro && (
							<div>
								<h2>{strings.languageSkills}</h2>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{
											strings.questionDifficultyRememberingWords
										}
									</label>
									<BooleanRadio
										name={
											TROUBLE_REMEMBERING_WHICH_WORD_TO_SAY
										}
										onChange={(value) => {
											this.updateTestUser(
												LANGUAGE,
												TROUBLE_REMEMBERING_WHICH_WORD_TO_SAY,
												value
											);
										}}
										value={
											language[
												TROUBLE_REMEMBERING_WHICH_WORD_TO_SAY
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.questionDifficultyShuffleWords}
									</label>
									<BooleanRadio
										name={SHUFFLES_WORDS_WHEN_SPEAKING}
										onChange={(value) => {
											this.updateTestUser(
												LANGUAGE,
												SHUFFLES_WORDS_WHEN_SPEAKING,
												value
											);
										}}
										value={
											language[
												SHUFFLES_WORDS_WHEN_SPEAKING
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{
											strings.questionDifficultyLongTimeFormulation
										}
									</label>
									<BooleanRadio
										name={
											SPENDS_LONG_TIME_FORMULATING_CORRECTLY
										}
										onChange={(value) => {
											this.updateTestUser(
												LANGUAGE,
												SPENDS_LONG_TIME_FORMULATING_CORRECTLY,
												value
											);
										}}
										value={
											language[
												SPENDS_LONG_TIME_FORMULATING_CORRECTLY
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{
											strings.questionSuspiciousLanguageIssues
										}
									</label>
									<BooleanRadio
										name={
											HAS_BEEN_SUSPICIONS_OF_DIFFICULTIES
										}
										onChange={(value) => {
											this.updateTestUser(
												LANGUAGE,
												HAS_BEEN_SUSPICIONS_OF_DIFFICULTIES,
												value
											);
										}}
										value={
											language[
												HAS_BEEN_SUSPICIONS_OF_DIFFICULTIES
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{
											strings.questionInvestigatedForLanguageIssues
										}
									</label>
									<BooleanRadio
										name={`language${HAS_BEEN_ASSESSED_FOR_DIFFICULTIES}`}
										onChange={(value) => {
											this.updateTestUser(
												LANGUAGE,
												HAS_BEEN_ASSESSED_FOR_DIFFICULTIES,
												value
											);
										}}
										value={
											language[
												HAS_BEEN_ASSESSED_FOR_DIFFICULTIES
											]
										}
									/>
								</div>
								{language[
									HAS_BEEN_ASSESSED_FOR_DIFFICULTIES
								] === true && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{strings.questionDiagnosis}
											</label>
											<BooleanRadio
												name={`language${HAS_DIAGNOSIS}`}
												onChange={(value) => {
													this.updateTestUser(
														LANGUAGE,
														HAS_DIAGNOSIS,
														value
													);
												}}
												value={language[HAS_DIAGNOSIS]}
											/>
										</div>
									</div>
								)}
								{language[
									HAS_BEEN_ASSESSED_FOR_DIFFICULTIES
								] === false && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{strings.questionSelfSuspicion}
											</label>
											<BooleanRadio
												name={`language${SUSPECTS_TO_HAVE_DIFFICULTIES}`}
												onChange={(value) => {
													this.updateTestUser(
														LANGUAGE,
														SUSPECTS_TO_HAVE_DIFFICULTIES,
														value
													);
												}}
												value={
													language[
														SUSPECTS_TO_HAVE_DIFFICULTIES
													]
												}
											/>
										</div>
									</div>
								)}
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{
											strings.questionCloseRelativeHasDifficulties
										}
									</label>
									<div className="lit-input__field lit-radios">
										{threeOptions.map((option) => {
											return (
												<span
													className="lit-radio"
													key={option}
												>
													<input
														checked={
															option ===
															language[
																CLOSE_RELATIVE_HAS_DIFFICULTIES
															]
														}
														className="lit-radio__field"
														id={`threeOptionsLanguage${option}`}
														name="threeOptionsLanguage"
														onChange={() => {
															this.updateTestUser(
																LANGUAGE,
																CLOSE_RELATIVE_HAS_DIFFICULTIES,
																option
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`threeOptionsLanguage${option}`}
													>
														{
															TEST_USER_INFO_LABELS[
																option
															]
														}
													</label>
												</span>
											);
										})}
									</div>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.questionRateLanguageSkills}
									</label>
									<div className="lit-input__field lit-radios">
										{selfRatings.map((selfRating) => {
											return (
												<span
													className="lit-radio"
													key={selfRating}
												>
													<input
														checked={
															selfRating ===
															language[
																SELF_RATING
															]
														}
														className="lit-radio__field"
														id={`selfRatingLanguage${selfRating}`}
														name="selfRatingLanguage"
														onChange={() => {
															this.updateTestUser(
																LANGUAGE,
																SELF_RATING,
																selfRating
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`selfRatingLanguage${selfRating}`}
													>
														{selfRating}
													</label>
												</span>
											);
										})}
									</div>
								</div>

								<h2>{strings.readingAndWriting}</h2>
								<h3>{strings.troubleWith}</h3>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.readingWordsCorrectly}
									</label>
									<BooleanRadio
										name={READING_WORDS_CORRECTLY}
										onChange={(value) => {
											const newDifficultiesWith = updateObject(
												difficultiesWith,
												{
													[READING_WORDS_CORRECTLY]: value,
												}
											);
											this.updateTestUser(
												READING_AND_WRITING,
												DIFFICULTIES_WITH,
												newDifficultiesWith
											);
										}}
										value={
											difficultiesWith[
												READING_WORDS_CORRECTLY
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.readingLongTexts}
									</label>
									<BooleanRadio
										name={READ_LONG_TEXTS}
										onChange={(value) => {
											const newDifficultiesWith = updateObject(
												difficultiesWith,
												{
													[READ_LONG_TEXTS]: value,
												}
											);
											this.updateTestUser(
												READING_AND_WRITING,
												DIFFICULTIES_WITH,
												newDifficultiesWith
											);
										}}
										value={
											difficultiesWith[READ_LONG_TEXTS]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.rememberingWhatYouHaveRead}
									</label>
									<BooleanRadio
										name={REMEMBERING_WHAT_YOU_HAVE_READ}
										onChange={(value) => {
											const newDifficultiesWith = updateObject(
												difficultiesWith,
												{
													[REMEMBERING_WHAT_YOU_HAVE_READ]: value,
												}
											);
											this.updateTestUser(
												READING_AND_WRITING,
												DIFFICULTIES_WITH,
												newDifficultiesWith
											);
										}}
										value={
											difficultiesWith[
												REMEMBERING_WHAT_YOU_HAVE_READ
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.writeWordsProperly}
									</label>
									<BooleanRadio
										name={WRITE_WORDS_PROPERLY}
										onChange={(value) => {
											const newDifficultiesWith = updateObject(
												difficultiesWith,
												{
													[WRITE_WORDS_PROPERLY]: value,
												}
											);
											this.updateTestUser(
												READING_AND_WRITING,
												DIFFICULTIES_WITH,
												newDifficultiesWith
											);
										}}
										value={
											difficultiesWith[
												WRITE_WORDS_PROPERLY
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.gettingThoughtsOntoPaper}
									</label>
									<BooleanRadio
										name={GETTING_THOUGHTS_ON_PAPER}
										onChange={(value) => {
											const newDifficultiesWith = updateObject(
												difficultiesWith,
												{
													[GETTING_THOUGHTS_ON_PAPER]: value,
												}
											);
											this.updateTestUser(
												READING_AND_WRITING,
												DIFFICULTIES_WITH,
												newDifficultiesWith
											);
										}}
										value={
											difficultiesWith[
												GETTING_THOUGHTS_ON_PAPER
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.readingAndWritingEnglish}
									</label>
									<BooleanRadio
										name={READING_AND_WRITING_ENGLISH}
										onChange={(value) => {
											const newDifficultiesWith = updateObject(
												difficultiesWith,
												{
													[READING_AND_WRITING_ENGLISH]: value,
												}
											);
											this.updateTestUser(
												READING_AND_WRITING,
												DIFFICULTIES_WITH,
												newDifficultiesWith
											);
										}}
										value={
											difficultiesWith[
												READING_AND_WRITING_ENGLISH
											]
										}
									/>
								</div>

								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.hasBeenAssessedForDifficulties}
									</label>
									<BooleanRadio
										name={`readingAndWriting${HAS_BEEN_ASSESSED_FOR_DIFFICULTIES}`}
										onChange={(value) => {
											this.updateTestUser(
												READING_AND_WRITING,
												HAS_BEEN_ASSESSED_FOR_DIFFICULTIES,
												value
											);
										}}
										value={
											readingAndWriting[
												HAS_BEEN_ASSESSED_FOR_DIFFICULTIES
											]
										}
									/>
								</div>
								{readingAndWriting[
									HAS_BEEN_ASSESSED_FOR_DIFFICULTIES
								] === true && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{strings.hasDiagnosis}
											</label>
											<BooleanRadio
												name={`readingAndWriting${HAS_DIAGNOSIS}`}
												onChange={(value) => {
													this.updateTestUser(
														READING_AND_WRITING,
														HAS_DIAGNOSIS,
														value
													);
												}}
												value={
													readingAndWriting[
														HAS_DIAGNOSIS
													]
												}
											/>
										</div>
									</div>
								)}
								{readingAndWriting[
									HAS_BEEN_ASSESSED_FOR_DIFFICULTIES
								] === false && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{strings.suspectsToHaveDyslexia}
											</label>
											<BooleanRadio
												name={`readingAndWriting${SUSPECTS_TO_HAVE_DYSLEXIA}`}
												onChange={(value) => {
													this.updateTestUser(
														READING_AND_WRITING,
														SUSPECTS_TO_HAVE_DYSLEXIA,
														value
													);
												}}
												value={
													readingAndWriting[
														SUSPECTS_TO_HAVE_DYSLEXIA
													]
												}
											/>
										</div>
									</div>
								)}
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.closeRelativeHasDifficulties}
									</label>
									<div className="lit-input__field lit-radios">
										{threeOptions.map((option) => {
											return (
												<span
													className="lit-radio"
													key={option}
												>
													<input
														checked={
															option ===
															readingAndWriting[
																CLOSE_RELATIVE_HAS_DIFFICULTIES
															]
														}
														className="lit-radio__field"
														id={`threeOptionsReadingAndWriting${option}`}
														name="threeOptionsReadingAndWriting"
														onChange={() => {
															this.updateTestUser(
																READING_AND_WRITING,
																CLOSE_RELATIVE_HAS_DIFFICULTIES,
																option
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`threeOptionsReadingAndWriting${option}`}
													>
														{
															TEST_USER_INFO_LABELS[
																option
															]
														}
													</label>
												</span>
											);
										})}
									</div>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.questionRateReadWriteSkills}
									</label>
									<div className="lit-input__field lit-radios">
										{selfRatings.map((selfRating) => {
											return (
												<span
													className="lit-radio"
													key={selfRating}
												>
													<input
														checked={
															selfRating ===
															readingAndWriting[
																SELF_RATING
															]
														}
														className="lit-radio__field"
														id={`selfRatingReadingAndWriting${selfRating}`}
														name="selfRatingReadingAndWriting"
														onChange={() => {
															this.updateTestUser(
																READING_AND_WRITING,
																SELF_RATING,
																selfRating
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`selfRatingReadingAndWriting${selfRating}`}
													>
														{selfRating}
													</label>
												</span>
											);
										})}
									</div>
								</div>

								<h2>{strings.mathematics}</h2>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.questionDifficultyMathematics}
									</label>
									<BooleanRadio
										name={
											HAS_DIFFICULTIES_WITH_MATH_OR_CALCULATION
										}
										onChange={(value) => {
											this.updateTestUser(
												MATH,
												HAS_DIFFICULTIES_WITH_MATH_OR_CALCULATION,
												value
											);
										}}
										value={
											math[
												HAS_DIFFICULTIES_WITH_MATH_OR_CALCULATION
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{
											strings.assessedForMathematicsDifficulties
										}
									</label>
									<BooleanRadio
										name={`math${HAS_BEEN_ASSESSED_FOR_DIFFICULTIES}`}
										onChange={(value) => {
											this.updateTestUser(
												MATH,
												HAS_BEEN_ASSESSED_FOR_DIFFICULTIES,
												value
											);
										}}
										value={
											math[
												HAS_BEEN_ASSESSED_FOR_DIFFICULTIES
											]
										}
									/>
								</div>
								{math[HAS_BEEN_ASSESSED_FOR_DIFFICULTIES] ===
									true && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{
													strings.diagnosisMathematicsIssues
												}
											</label>
											<BooleanRadio
												name={`math${HAS_DIAGNOSIS}`}
												onChange={(value) => {
													this.updateTestUser(
														MATH,
														HAS_DIAGNOSIS,
														value
													);
												}}
												value={math[HAS_DIAGNOSIS]}
											/>
										</div>
									</div>
								)}
								{math[HAS_BEEN_ASSESSED_FOR_DIFFICULTIES] ===
									false && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{
													strings.suspectsToHaveDyscalculia
												}
											</label>
											<BooleanRadio
												name={`math${SUSPECTS_TO_HAVE_DYSCALCULIA}`}
												onChange={(value) => {
													this.updateTestUser(
														MATH,
														SUSPECTS_TO_HAVE_DYSCALCULIA,
														value
													);
												}}
												value={
													math[
														SUSPECTS_TO_HAVE_DYSCALCULIA
													]
												}
											/>
										</div>
									</div>
								)}
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{
											strings.closeRelativeHasDifficultiesWithMathematics
										}
									</label>
									<div className="lit-input__field lit-radios">
										{threeOptions.map((option) => {
											return (
												<span
													className="lit-radio"
													key={option}
												>
													<input
														checked={
															option ===
															math[
																CLOSE_RELATIVE_HAS_DIFFICULTIES
															]
														}
														className="lit-radio__field"
														id={`threeOptionsMath${option}`}
														name="threeOptionsMath"
														onChange={() => {
															this.updateTestUser(
																MATH,
																CLOSE_RELATIVE_HAS_DIFFICULTIES,
																option
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`threeOptionsMath${option}`}
													>
														{
															TEST_USER_INFO_LABELS[
																option
															]
														}
													</label>
												</span>
											);
										})}
									</div>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.selfRatingMathematicsSkills}
									</label>
									<div className="lit-input__field lit-radios">
										{selfRatings.map((selfRating) => {
											return (
												<span
													className="lit-radio"
													key={selfRating}
												>
													<input
														checked={
															selfRating ===
															math[SELF_RATING]
														}
														className="lit-radio__field"
														id={`selfRatingMath${selfRating}`}
														name="selfRatingMath"
														onChange={() => {
															this.updateTestUser(
																MATH,
																SELF_RATING,
																selfRating
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`selfRatingMath${selfRating}`}
													>
														{selfRating}
													</label>
												</span>
											);
										})}
									</div>
								</div>

								<h2>{strings.concentration}</h2>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{
											strings.questionProblemsWithConcentration
										}
									</label>
									<BooleanRadio
										name={
											HAS_DIFFICULTIES_WITH_CONCENTRATION_AND_STAMINA
										}
										onChange={(value) => {
											this.updateTestUser(
												ADHD,
												HAS_DIFFICULTIES_WITH_CONCENTRATION_AND_STAMINA,
												value
											);
										}}
										value={
											adhd[
												HAS_DIFFICULTIES_WITH_CONCENTRATION_AND_STAMINA
											]
										}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider expand">
										{strings.questionBoredom}
									</label>
									<BooleanRadio
										name={EASILY_BORED_AND_RESTLESS}
										onChange={(value) => {
											this.updateTestUser(
												ADHD,
												EASILY_BORED_AND_RESTLESS,
												value
											);
										}}
										value={adhd[EASILY_BORED_AND_RESTLESS]}
									/>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.questionAssessedForADHD}
									</label>
									<BooleanRadio
										name={`adhd${HAS_BEEN_ASSESSED_FOR_DIFFICULTIES}`}
										onChange={(value) => {
											this.updateTestUser(
												ADHD,
												HAS_BEEN_ASSESSED_FOR_DIFFICULTIES,
												value
											);
										}}
										value={
											adhd[
												HAS_BEEN_ASSESSED_FOR_DIFFICULTIES
											]
										}
									/>
								</div>
								{adhd[HAS_BEEN_ASSESSED_FOR_DIFFICULTIES] ===
									true && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{
													strings.questionDiagnosedForADHD
												}
											</label>
											<BooleanRadio
												name={`adhd${HAS_DIAGNOSIS}`}
												onChange={(value) => {
													this.updateTestUser(
														ADHD,
														HAS_DIAGNOSIS,
														value
													);
												}}
												value={adhd[HAS_DIAGNOSIS]}
											/>
										</div>
									</div>
								)}
								{adhd[HAS_BEEN_ASSESSED_FOR_DIFFICULTIES] ===
									false && (
									<div className="lit-input">
										<div className="lit-input inline">
											<label className="lit-input__label wider">
												{
													strings.suspectsToHaveAddOrAdhd
												}
											</label>
											<BooleanRadio
												name={`adhd${SUSPECTS_TO_HAVE_ADD_OR_ADHD}`}
												onChange={(value) => {
													this.updateTestUser(
														ADHD,
														SUSPECTS_TO_HAVE_ADD_OR_ADHD,
														value
													);
												}}
												value={
													adhd[
														SUSPECTS_TO_HAVE_ADD_OR_ADHD
													]
												}
											/>
										</div>
									</div>
								)}
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.closeRelativeHasADDorADHD}
									</label>
									<div className="lit-input__field lit-radios">
										{threeOptions.map((option) => {
											return (
												<span
													className="lit-radio"
													key={option}
												>
													<input
														checked={
															option ===
															adhd[
																CLOSE_RELATIVE_HAS_DIFFICULTIES
															]
														}
														className="lit-radio__field"
														id={`threeOptionsAdhd${option}`}
														name="threeOptionsAdhd"
														onChange={() => {
															this.updateTestUser(
																ADHD,
																CLOSE_RELATIVE_HAS_DIFFICULTIES,
																option
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`threeOptionsAdhd${option}`}
													>
														{
															TEST_USER_INFO_LABELS[
																option
															]
														}
													</label>
												</span>
											);
										})}
									</div>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label wider">
										{strings.questionRateConcentration}
									</label>
									<div className="lit-input__field lit-radios">
										{selfRatings.map((selfRating) => {
											return (
												<span
													className="lit-radio"
													key={selfRating}
												>
													<input
														checked={
															selfRating ===
															adhd[SELF_RATING]
														}
														className="lit-radio__field"
														id={`selfRatingAdhd${selfRating}`}
														name="selfRatingAdhd"
														onChange={() => {
															this.updateTestUser(
																ADHD,
																SELF_RATING,
																selfRating
															);
														}}
														required
														type="radio"
													/>
													<label
														className="lit-radio__label"
														htmlFor={`selfRatingAdhd${selfRating}`}
													>
														{selfRating}
													</label>
												</span>
											);
										})}
									</div>
								</div>
							</div>
						)}

						{/* Make changes here */}
						<br />
						<div>
							<label>
								<input
									id="agreedToPrivacyPolicy"
									name="agreedToPrivacyPolicy"
									required
									type="checkbox"
								/>{' '}
								{strings.privacyPolicyLabel}
							</label>
							<br />
							{strings.formatString(
								strings.privacyPolicyText,
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://literate.no/personvernerklaering/"
								>
									{strings.privacyPolicy}
								</a>
							)}
						</div>

						<button
							className="lit-btn lit-btn--huge bg-primary"
							type="submit"
						>
							{strings.start}
						</button>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {
	onGoToNextStep: goToNextStep,
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(Introduksjon);
