import React from 'react';

export const text = (
	<span>
		<h2 className="text-center">
			{`Historia om Ignaz Semmelweis – «mødrefrelsaren»`}
		</h2>
		<p>
			<strong>
				{`Den østerriksk-ungarske legen Ignaz Semmelweis (1818-1865) er
				kjent for å ha påvist samanhengen mellom manglande handhygiene
				blant legar og førekomst av barselfeber. Gjennom enkel handvask
				fekk han dødelegheita blant nybakte mødrer til å synke
				dramatisk. Han blei derfor kjent som «mødrefrelsaren».`}
			</strong>
		</p>
		<p>
			{`Barseltida omfattar dei første 10-14 dagane etter fødselen. I denne
			perioden skjer det store endringar i kroppen etter det avsluttande
			svangerskapet og fødselen. Nokre gonger kan nybakte mødrer oppleve å
			få feber. I nokre tilfelle blir feberen vedvarande over fleire døgn,
			og da blir nemninga barselfeber brukt. I dag veit ein at barselfeber
			dreier seg om ein bakteriell infeksjon i livmora om oppstår under
			eller etter fødselen, og som kan bli behandla med antibiotika. Slik
			har det ikkje alltid vore. Barselfeberen har førekomme til alle
			tider. Det var lenge ei utbreidd oppfatning at barselfeber kom av
			utanomjordiske påverkingar, som for eksempel kosmiske forandringar i
			lufta. Det var forhold ein ikkje kunne gjere noko med, og ein antok
			derfor at sjukdommen var uunngåeleg.`}
		</p>
		<p>
			{`I første halvdel av 1800-talet blei det bygd sjukehus i dei fleste
			store byane i Europa. Sjølv om det framleis var med vanleg å føde
			heime, blei det stadig meir vanleg å føde på sjukehuset. Det var
			gjerne kvinner med kompliserte svangerskap og ugifte kvinner som
			fødde på sjukehus. Samtidig med at talet på fødslar auka på
			fødeklinikkar, oppstod ei dramatisk auking i førekomsten av
			barselfeber. Dødelegheita blant kvinner som fødde på sjukehus, var
			opptil 25-40 prosent høg nokre stader.`}
		</p>
		<p>
			{`Ignaz Semmelweis tok medisinsk embetseksamen ved Universitetet i
			Wien i 1844 og spesialiserte seg innan fødselshjelp. I 1846 begynte
			han å jobbe som lege ved fødselsklinkikken i Wien. Denne klinikken
			blei brukt i utdanninga av legar og jordmødrer, og var delt i to
			avdelingar. 1.-avdelinga, der legane jobba, blei brukt til å
			undervise medisinstudentar. 2.-avdelinga, der jordmødrene jobba,
			blei brukt til opplæring av jordmorstudentane. Overklassekvinnene
			blei som regel lagt inn på 1.-avdeling og følgt av legar under
			fødselen, mens dei fattige kvinnene ble lagt inn på 2.-avdeling og
			blei forløyst av jordmødrer.`}
		</p>
		<p>
			{`Semmelveis blei forferda då han skjønte at dødelegheita var langt
			høgare på den eine avdelinga enn på den andre. På 1.-avdelinga
			døydde 18 prosent av kvinnene i barselfeber, mot berre to prosent på
			2.-avdelinga. Han samla inn opplysingar og laga eit diagram som
			viste talet på dødsfall som følge av barselfeber på dei to
			avdelingane gjennom dei siste åra. Semmelveis undra seg over at
			dersom utanomjordiske forandringar var årsaka til barselfeber –
			korleis kunne desse heimsøke berre den eie avdelinga, og ikkje den
			andre?`}
		</p>
		<p>
			{`Semmelveis gjekk systematisk til verks for å finne ut kvifor så
			mange kvinner døydde på 1.-avdelinga etter ein fødsel utan
			komplikasjonar. Han samanlikna alle moglege forskjellar mellom dei
			to avdelingane. Han slo frå seg at tettleiken blant pasientane var
			ei mogleg forklaring, ettersom det var fleire kvinner på romma på
			2.-avdelinga. Det blei også raskt klart at dei mannlege
			legestudentane ikkje undersøkte dei fødande kvinnene på ein meir
			hardhendt måte enn dei kvinnelege jordmødrene. Kunne det da vere
			psykologiske forklaringar på forskjellane mellom dei to avdelingane?
			Presten måtte nemleg gå gjennom fem fødestuer på 1.-avdelingane for
			å komme fram til sjukerommet og gi ei døyande kvinne den siste olja.
			På 2.-avdelinga, derimot, gjekk han rett til sjukerommet utan å
			måtte gå gjennom fødestuene. Semmelweis ønskte å finne ut om synet
			av presten og lyden av bjølla som ringde før han gjekk inn i ei
			fødestue, gjorde kvinnene engstelege og dermed meir mottakelege for
			barselfeber. Han overtalte presten til å gå ein omveg og fekk slutt
			på ringelyden, slik at presten gjekk umerka til sjukerommet på
			1.-avdelinga. Men dødelegheita på 1.-avdelinga gjekk ikkje ned.
			Kunne da forskjellane mellom avdelingane skyldast fødestilling?
			Semmelveis blei tipsa om at kvinnene på 2.-avdelinga fødde i
			sideleie, mens dei på 1.-avdelinga fødde mens dei låg på rygg. Han
			fekk innført ein regel om at også kvinnene på 1.-avdelinga skulle
			føde i sideleie. Men heller ikkje dette førte til at dødelegheita
			gjekk ned.`}
		</p>
		<p>
			{`Det store gjennombrotet kom da Semmelwies’ kollega og nære venn,
			Jakob Kolletscha, skar seg i fingeren under ein obduksjon av liket
			av ei kvinne som hadde døydd av barselfeber. Kolletscha fekk ein
			infeksjon og utvikla same symptom som kvinnene med barselfeber. Han
			døydde kort tid etter. Dette sette Semmelveis på sporet av
			samanhengen mellom obduksjonar og barselfeber. På 1.-avdelinga
			starta legane og medisinstudentane arbeidsdagen med å setje i verk
			obduksjonar, før dei gjekk inn og jobba med dei fødande kvinnene.
			Jordmødrene på 2.-avdelinga deltok ikkje i obduksjon av lik, og fekk
			dermed ikkje den karakteristiske lukta av «likstoff» på hendene
			sine. Semmelveis beordra derfor studentane og kollegaene til å vaske
			hendene sine og instrumenta med klorkalk etter å ha jobba med dei
			døde kvinnekroppane. Fjerning av likstofflukta medførte at dødstala
			gjekk dramatisk ned i løpet av kort tid. Talet på kvinner som fekk
			barselfeber, blei snart lågare på 1.-avdelinga enn på 2.-avdelinga.
			Semmelveis konkluderte at likstoffa festa seg på hendene under
			obduksjonen og blei ført inn i underlivet til kvinnene når dei blei
			undersøkt under fødselen.`}
		</p>
		<p>
			{`	Sjølv om tiltaket hadde stor effekt, var barselfeberen framleis
			ikkje heilt utrydda. Semmelweis observerte at kvinner kunne få
			barselfeber sjølv om legane ikkje hadde obdusert tidlegare på dagen,
			og sjølv om dei vaska hendene med klorkalk etter ein obduksjon. Ein
			dag starta Semmelweis og kollegaene hans arbeidsdagen med å
			undersøke ei kvinne som var sjuk av barselfeber. Dei hadde vaska
			hendene grundig før denne undersøkinga. Etter undersøkinga av den
			sjuke kvinna undersøkte dei tolv heilt friske kvinner utan å vaske
			hendene først. Elleve av dei tolv kvinnene fekk barselfeber kort tid
			etter og døydde. Dette sette Semmelweis på tanken om at sjukdommen
			ikkje berre skuldast likstoffer, men at han også kunne bli overført
			gjennom «rotne stoff» frå levande personar. Han antok at dei rotne
			stoffa kom frå den sjuke kvinna og hadde klistra seg på hendene til
			studentane og legane. Korleis smitte blir overført mellom menneske,
			var ikkje klarlagt på det tidspunktet da Semmelveis trekte
			slutningane sine. Men hans funn blei grunnlaget for den moderne
			bakteriologien mange år seinare.`}
		</p>
		<p>
			{`Sjølv om Semmelveis reduserte dødelegheita ved å innføre handvask,
			blei teoriane hans møtt med stor skepsis. Han blei sett på som ein
			kverulant. Etter ein bitter strid med leiarane på sjukehuset, blei
			han oppsagd. Mange av kollegaene hans nekta å erkjenne at
			barselfeber kunne overførast gjennom direkte kontakt mellom
			menneske. Dei godtok ikkje at dei sjølve kunne vere årsaka til mange
			dødsfall. Dei hadde eit heilag yrke og kunne umogleg ha skitne
			hender, som tok liv. Semmelveis blei håna og latterleggjord for
			påstandane sine. Han blei til slutt tvangsinnlagd på sinnssjukehus,
			der han seinare døydde.`}
		</p>
	</span>
);
export default text;
