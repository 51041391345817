import './styles.scss';
import React, {Component} from 'react';
class MinuteSpeechLoader extends Component {
	render() {
		return (
			<div className="lit-loader-container">
				<div className="loader">
					<div className="circle"></div>
					<div className="circle"></div>
				</div>
			</div>
		);
	}
}
export default MinuteSpeechLoader;
