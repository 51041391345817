import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';
import FinishSound from '../../components/FinishSound';

import Countdown from '../../components/Countdown';
import Counter from '../../components/Counter';
import classNames from 'classnames';
import {VARIATION} from '../../constants';

const INPUT_PAD = 2; // How many to show before and after
const INPUT_HEIGHT = 93; // inkl margin

class Test extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();
		this.data = require(`../../assets/testdata/${variation}/${language}/skrivehastighet-data.json`);
		this.taskIsOver =
			language === 'de'
				? require(`../../assets/sounds/${variation}/${language}/SCR062.mp3`)
				: null;
		this.text = this.data[0].value;

		this.inputRefs = {};
		this.myRef = React.createRef();
		const inputs = [
			{
				isPlaceholder: true,
				visiblePlacement: 1,
				value: '',
			},
			{
				isPlaceholder: true,
				visiblePlacement: 2,
				value: '',
			},
			{
				visiblePlacement: 3,
				value: '',
			},
		];

		this.state = {
			count: 0,
			currentInputIndex: 2,
			inputs,
			renderFinalSound: false,
			isComplete: false,
		};

		this.centerViewportAround = this.centerViewportAround.bind(this);
		this.addPlaceholderInputs = this.addPlaceholderInputs.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.incrementCurrentInputIndex = this.incrementCurrentInputIndex.bind(
			this
		);
	}

	componentDidMount() {
		const {currentInputIndex} = this.state;

		this.addPlaceholderInputs(currentInputIndex);
		this.centerViewportAround(currentInputIndex);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.isComplete !== this.state.isComplete &&
			this.state.isComplete
		) {
			if (this.submitButton) {
				this.submitButton.focus();
			}
		}
	}

	updateInput(index, value) {
		const {inputs} = this.state;

		const currentValue = inputs[index].value;
		const valueLengthDiff = Math.abs(currentValue.length - value.length);

		if (valueLengthDiff > 1) {
			return;
		}

		inputs[index].value = value;

		this.setState({
			inputs,
		});
	}

	handleFormSubmit(e) {
		e.preventDefault();
		this.incrementCurrentInputIndex();
	}

	incrementCurrentInputIndex() {
		const {currentInputIndex} = this.state;

		const newIndex = currentInputIndex + 1;

		this.addPlaceholderInputs(newIndex);
		this.centerViewportAround(newIndex);
		this.myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
	}

	addPlaceholderInputs(index) {
		const {inputs} = this.state;

		if (inputs.length === index + 1) {
			inputs.push({
				value: '',
			});
			inputs.push({
				value: '',
			});
		} else if (inputs.length === index + 2) {
			inputs.push({
				value: '',
			});
		}

		const count = inputs.filter((input) => input.value.length).length;

		this.setState({
			count,
			currentInputIndex: index,
		});
	}

	centerViewportAround(index) {
		const {inputs} = this.state;

		let newInputs = inputs.slice();

		for (let i = 0; i < newInputs.length; i++) {
			let input = newInputs[i];
			if (index === i - 2) {
				input.visiblePlacement = 5;
			} else if (index === i - 1) {
				input.visiblePlacement = 4;
			} else if (index === i) {
				input.visiblePlacement = 3;
			} else if (index === i + 1) {
				input.visiblePlacement = 2;
			} else if (index === i + 2) {
				input.visiblePlacement = 1;
			} else {
				input.visiblePlacement = 0;
			}
		}

		this.setState(
			{
				inputs: newInputs,
			},
			() => {
				this.inputRefs[index].focus();
			}
		);
	}

	render() {
		const {onNextStep} = this.props;

		const {count, currentInputIndex, inputs, isComplete} = this.state;

		return (
			<div>
				<p>{strings.writeFollowingSentence}</p>
				<div className="pesudo" ref={this.myRef}>
					<h3 className="text">
						<strong>{this.text}</strong>
					</h3>
				</div>
				<div className="lit-infobar">
					<Countdown
						duration={120}
						onComplete={() => {
							this.setState({
								isComplete: true,
								renderFinalSound: true,
							});
						}}
					/>
					<Counter count={count} />
				</div>
				<div
					className="lit-test viewport lit-revealable"
					style={{
						height: (1 + INPUT_PAD * 2) * INPUT_HEIGHT + 'px',
					}}
				>
					<div
						className={classNames({
							inputs: true,
							completed: isComplete,
						})}
						style={{
							transform:
								'translateY(-' +
								(currentInputIndex - INPUT_PAD) * INPUT_HEIGHT +
								'px)',
						}}
					>
						<form onSubmit={this.handleFormSubmit}>
							{inputs.map((input, i) => {
								return (
									<div
										className={classNames({
											'lit-input lit-revealed': true,
											['lit-input--' +
											input.visiblePlacement]: true,
											placeholder: input.isPlaceholder,
										})}
										key={i}
									>
										<input
											className={classNames({
												'lit-input__field': true,
												disabled:
													input.visiblePlacement < 3,
											})}
											disabled={isComplete}
											onChange={(ev) => {
												let value = ev.target.value;

												this.updateInput(i, value);
											}}
											onFocus={() => {
												this.addPlaceholderInputs(i);
												this.centerViewportAround(i);
											}}
											placeholder={
												input.visiblePlacement === 3
													? strings.writeHere
													: ''
											}
											ref={(ref) => {
												this.inputRefs[i] = ref;
											}}
											spellCheck={false}
											autoComplete="off"
											autoCorrect="off"
											autoCapitalize="off"
											type="text"
											value={input.value}
										/>
									</div>
								);
							})}
							<input
								style={{visibility: 'hidden'}}
								type="submit"
							/>
						</form>
					</div>
				</div>
				{(isComplete || this.state.renderFinalSound) && (
					<div>
						<span>
							<br></br>
							<FinishSound
								renderFinalSound={this.state.renderFinalSound}
								text={strings.Fast_writing_is_Finished}
								audioSrc={this.taskIsOver}
							/>
							<button
								className="lit-btn bg-primary"
								onClick={() => {
									const results = inputs
										.filter((input) => input.value)
										.map((input) => input.value);
									onNextStep(results);
								}}
								ref={(ref) => {
									this.submitButton = ref;
								}}
							>
								{strings.next}
							</button>
						</span>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
