import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';
// import './styles.scss';
import tutorialVideo from './assets/4006868833093d06696ae39c73ba2ffb.mp4';

import previewVideoNo from './assets/4006868833093d06696ae39c73ba2ffb.mp4';
import previewVideoNn from './assets/4006868833093d06696ae39c73ba2ffb.mp4';

import previewVideoSe from './assets/4006868833093d06696ae39c73ba2ffb.mp4';
import previewVideoDe from './assets/DE_Orddiktat.mp4';

import {VARIATION} from '../../constants';

import AudioPlayer from '../../components/AudioPlayer';

class Intro extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();
		const previewVideoObj = {
			no: previewVideoNo,
			nn: previewVideoNn,
			se: previewVideoSe,
			de: previewVideoDe,
		};

		const audio = require(`../../assets/sounds/${variation}/${language}/1 0 Orddiktat Intro.mp3`);
		this.audio = audio;
		this.video = previewVideoObj[language];
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div className="lit-intro">
				<p
					style={{
						marginBottom: 0,
					}}
				>
					<AudioPlayer
						autoplay
						inline
						onPause={() => {
							videoRef.play();
						}}
						src={this.audio}
					/>
					{strings.orddiktatIntro}
				</p>
				<div className="text-center">
					<div className="lit-preview">
						<p className="lit-preview__label">
							{strings.testLooksLikeThis}
						</p>
						<video
							className="lit-preview__video"
							loop
							ref={(ref) => {
								videoRef = ref;
							}}
							src={this.video}
						/>
					</div>
				</div>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
