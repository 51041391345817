import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';

import {updateObject} from '../../utils/reducerUtils';

import AudioPlayer from '../../components/AudioPlayer';

import {VARIATION} from '../../constants';

class Tutorial extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();
		this.tutorial = require(`../../assets/testdata/${variation}/${language}/tulleord-tutorial.js`);
		this.tutorialAssignment = this.tutorial.tutorialAssignment;
		this.currentLang = language;
		this.assignments = this.tutorial.assignments;
		this.exampleSound = this.tutorial.exampleSound;
		this.yourTurnSound = this.tutorial.yourTurnSound;
		this.startTheTestSound = this.tutorial.startTheTestSound;
		const results = {};

		for (const assignment of this.assignments) {
			results[assignment.id] = '';
		}
		this.state = {
			hasPlayedFirstAudio: false,
			results,
		};

		this.audioRefs = {};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}
	getFirstExampleText() {
		const hereYouSeeExample = this.tutorial.hereYouSeeExample;
		return hereYouSeeExample[0].textData;
	}
	render() {
		const {onNextStep} = this.props;

		const {hasPlayedFirstAudio, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues;

		return (
			<div className="lit-test">
				<div className="lit-words-wrapper demonstration">
					<div className="lit-words">
						<span className="lit-input__label lit-input__label--count">
							1
						</span>
						<div className="lit-word-list">
							{this.tutorialAssignment.choices.map(
								(choice, i) => {
									const idWord = i + choice.word;
									return (
										<span className="lit-word" key={idWord}>
											<input
												checked={choice.isCorrect}
												className="lit-word__field"
												id={idWord}
												onChange={(e) => {
													e.preventDefault();
												}}
												type="radio"
											/>
											<label
												className="lit-word__label"
												htmlFor={idWord}
											>
												<div className="checkbox" />
												{choice.word}
											</label>
										</span>
									);
								}
							)}
						</div>
					</div>
				</div>
				<div className="lit-tulleord lit-tulleord__tutorial">
					<div>
						<p className="instruction first">
							<AudioPlayer
								autoplay
								id="exampleSound"
								inline
								onComplete={() => {
									if (!hasPlayedFirstAudio) {
										this.setState({
											hasPlayedFirstAudio: true,
										});
										if(this.currentLang !== 'de')
										this.dinTurAudioPlayer.handleStartPlayer();
									}
								}}
								refs={this.audioRefs}
								src={this.exampleSound}
							/>
							<span>{this.getFirstExampleText()}</span>
						</p>
					</div>
					<br></br>
					{this.currentLang !== 'de' && (
						<p className="instruction">
							<AudioPlayer
								inline
								ref={(ref) => {
									this.dinTurAudioPlayer = ref;
								}}
								refs={this.audioRefs}
								src={this.yourTurnSound}
							/>
							{strings.tulleordCTA}
						</p>
					)}

					{this.assignments.map((assignment) => {
						const {id, choices} = assignment;

						const currentAssignmentValue = results[id];
						let comment = null;
						for (const choice of choices) {
							if (currentAssignmentValue === choice.word) {
								comment = (
									<p className="instruction">
										<AudioPlayer
											autoplay
											inline
											onComplete={() => {
												if (
													this.narDuStarterAudioPlayer
												) {
													this.narDuStarterAudioPlayer.handleStartPlayer();
												}
											}}
											refs={this.audioRefs}
											src={choice.sound}
										/>
										{choice.comment}
									</p>
								);
							}
						}

						return (
							<div className="lit-words-wrapper" key={id}>
								<div className="lit-words">
									<span className="lit-input__label lit-input__label--count">
										{id}
									</span>
									<div className="lit-word-list">
										{choices.map((choice, i) => {
											const idWord = i + choice.word;
											return (
												<span
													className="lit-word"
													key={idWord}
												>
													<input
														checked={
															currentAssignmentValue ===
															choice.word
														}
														className="lit-word__field"
														id={idWord}
														onChange={() =>
															this.setResults(
																id,
																choice.word
															)
														}
														type="radio"
													/>
													<label
														className="lit-word__label"
														htmlFor={idWord}
													>
														<div className="checkbox" />
														{choice.word}
													</label>
												</span>
											);
										})}
									</div>
								</div>
								{comment}
							</div>
						);
					})}
				</div>
				{isNextButtonVisible && (
					<p className="instruction">
						<AudioPlayer
							inline
							ref={(ref) => {
								this.narDuStarterAudioPlayer = ref;
							}}
							refs={this.audioRefs}
							src={this.startTheTestSound}
						/>
						{strings.tulleordStarterTesten}
					</p>
				)}
				{isNextButtonVisible && (
					<button
						className="lit-btn bg-primary"
						onClick={() => onNextStep(results)}
					>
						{strings.startTest}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Tutorial);
